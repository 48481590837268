import React, { useState, useEffect, useCallback } from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Link } from 'react-router-dom';
import './ThumbnailLayout.css';

//FIREBASE
import { doc, onSnapshot, collection, getDoc } from 'firebase/firestore';
import { db, auth } from '../firebase';

//FUNCTIONS
import { FetchCreatorVideos } from './FetchCreatorVideos';
import { registerListener } from '../components/ListenerManager';
import { getYoutubeCaptionsPython } from './getVideoCaptions';
import he from 'he';
import { CLIENT_ID, SCOPES } from '../googleOAuth';

//IMPORT UI
import PreviewPoints from './PreviewPoints';

//IMAGES
import processlogo from '../assets/gifmedia/bumpups-2.gif';
import processVideoIcon from '../assets/images/upmove-v1.png';
import viewVideoValue from '../assets/images/v2-settings.png';
import bumpupsBadge from '../assets/images/bumpups-tans-white-v1.png';
import bumpupSmall from '../assets/images/bumpup-small.png';
import noAccessImageDefault from '../assets/images/thumbnailPlacehlder.jpg';
import noAccessImageActive from '../assets/images/thumbnailPlacehlder-active.jpg';
// import commentIcon from '../assets/images/comment-v2.png';
// import viewsIcon from '../assets/images/views-v1.png';
// import likesIcon from '../assets/images/thumbup-v1.png';
import utubeLogo from '../assets/images/v2-creator.png';
import utubeLogoon from '../assets/images/v2-youtubeon.png';



const ThumbnailLayout = ({searchvideo, setSearchVideo, selectedDate, setSelectedDate, accessToken, setAccessToken, channelId, setChannelId }) => {




  const [isLoadingPreview, setIsLoadingPreview] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [videos, setVideos] = useState([]);

  const [showActionMenu, setShowActionMenu] = useState(null);
  

  const [doneVideos, setDoneVideos] = useState([]); // New state variable to hold doneVideos

  const [progress, setProgress] = useState(0);

  // FUNCTIONS LOGIC BEGINS
  // FUNCTIONS LOGIC BEGINS
  // FUNCTIONS LOGIC BEGINS
  // FUNCTIONS LOGIC BEGINS



  // Search Logic
  // Search Logic
  // Search Logic


  const [isVideoSearched, setisVideoSearched] = useState(false);

  useEffect(() => {
    if (searchvideo && searchvideo.length > 0) { // Check if searchvideo contains data
      setVideos(searchvideo);
      setisVideoSearched(true);
    } else {
      setisVideoSearched(false); // Ensure isVideoSearched is false when there's no search data
    }
  }, [searchvideo]);
  
  

// Formats duration in seconds into a readable string (HH:MM:SS or MM:SS)
const formatDuration=(seconds)=>{
  const hours=Math.floor(seconds/3600);
  const minutes=Math.floor((seconds%3600)/60);
  const remainingSeconds=seconds%60;

  let formattedDuration="";

  if(hours>0){
    formattedDuration+=`${hours}h `;
    if(minutes>0){
      formattedDuration+=`${minutes}m`;
    }
  }else{
    formattedDuration=`${minutes}:${remainingSeconds.toString().padStart(2,'0')}`;
  }

  return formattedDuration.trim();
};

  

   

useEffect(() => {
  if (accessToken) {
    // If accessToken exists (is truthy), setIsLoadingPreview to false
    setIsLoadingPreview(true);
  } else {
    // If accessToken does not exist (is falsy), setIsLoadingPreview to true
    setIsLoadingPreview(false);
  }
}, [accessToken]); // This effect depends on accessToken and will re-run when accessToken changes

      





const [isContentAvailable, setIsContentAvailable] = useState(false);

const checkFirestore = useCallback(async (userId, channelIdToUse) => {
  const userDocRef = doc(db, "users", userId);
  const videoCacheRef = doc(userDocRef, "contentCatalog", "youtubeCatalog", "userChannels", channelIdToUse);

  try {
    const cacheSnap = await getDoc(videoCacheRef);
    if (cacheSnap.exists() && cacheSnap.data()[selectedDate]) {
      const videosFromFirestore = cacheSnap.data()[selectedDate];
      setVideos(videosFromFirestore);
      setProgress(100); // Update progress

      const localStorageKey = `ISOfinalvideoCache_${userId}_${channelIdToUse}_${selectedDate}`;
      localStorage.setItem(localStorageKey, JSON.stringify(videosFromFirestore));

      return true; // Indicate that content is available
    } else {
      return false; // Indicate that content needs to be fetched
    }
  } catch (error) {
    return false; // Consider content not available in case of error
  }
}, [selectedDate]);




const [noVideosMade, setnoVideosMade] = useState(false);


useEffect(() => {
  const checkDataAvailability = async () => {
    // console.log("Starting to fetch creator videos for the selected date...");
    const channelIdToUse = channelId || 'default_value';
    const user = auth.currentUser;

    const localStorageKey = `ISOfinalvideoCache_${user.uid}_${channelIdToUse}_${selectedDate}`;
    // console.log(`Checking localStorage for key: ${localStorageKey}`);
    const cachedVideos = localStorage.getItem(localStorageKey);

    setProgress(10); // Initial progress
      // Check if the stringified version of videos is exactly "[]"
      if (cachedVideos === "[]") {
        // console.log("Found empty videos array in localStorage for the selected date.");
        setnoVideosMade(true); // Indicate no videos were made
      }
  
    if (cachedVideos && cachedVideos !== "[]") {
      // console.log("Found videos in localStorage, loading...");
      setVideos(JSON.parse(cachedVideos));
      setProgress(100); // Directly set to 100% if videos are loaded from local storage
      setIsContentAvailable(true); // Content is available
      setnoVideosMade(false); // Indicate no videos were made

        } else {
      // Proceed to check Firestore if not found in localStorage
      const contentAvailableInFirestore = await checkFirestore(user.uid, channelIdToUse);
      if (!contentAvailableInFirestore) {
        // No content available in Firestore, clear videos and indicate no content available
        setVideos([]);
        setIsContentAvailable(false);
        setisVideoSearched(false);
        setnoVideosMade(false); // Indicate no videos were made
      } // Otherwise, setIsContentAvailable(true) will be handled within checkFirestore
    }
  };

  checkDataAvailability();
}, [selectedDate, channelId, setVideos, setProgress, setIsContentAvailable, setnoVideosMade, checkFirestore]);






const handleFetchVideos = async () => {
  await FetchCreatorVideos({
    channelId: channelId || 'default_value', // Replace 'yourChannelId' with actual channelId state or constant
    getCurrentUser: auth.currentUser,
    setIsLoading: setIsLoading,
    setProgress: setProgress, // Define yourSetProgressMethod or replace with actual method
    selectedDate: selectedDate, // Replace 'yourSelectedDate' with actual selectedDate state or constant
    setVideos: setVideos, // Define yourSetVideosMethod or replace with actual method
    setIsContentAvailable: setIsContentAvailable, // Define yourSetIsContentAvailableMethod or replace with actual method
    setnoVideosMade: setnoVideosMade,
  });
};




const [videoStats, setVideoStats] = useState({}); 
const [initialVideoStats, setInitialVideoStats] = useState({});


useEffect(() => {
  const handleClickOutside = (event) => {
    if (
      showActionMenu &&
      !event.target.closest('.action-dropdown') &&
      !event.target.closest('.action-icon') &&
      !event.target.matches('.dropdown-item')
    ) {
      setShowActionMenu(false);
    }
  };

  document.addEventListener('click', handleClickOutside);

  const fetchVideoData = async () => {
    const user = auth.currentUser;
    const userDocRef = doc(db, 'users', user.uid);
    const valueDataCollectionRef = collection(userDocRef, 'valueData');

    const unsubscribe = onSnapshot(valueDataCollectionRef, (querySnapshot) => {
      const doneVideoIds = [];
      const stats = {};  // Object to store video stats
      const initialStats = {};  // Object to store initial video stats

      querySnapshot.forEach((doc) => {
        const videoId = doc.id.startsWith('youtube-') ? doc.id.substring('youtube-'.length) : doc.id;
        doneVideoIds.push(videoId);

        // Store the current stats in the object
        stats[videoId] = {
          viewCount: doc.data().curr_view_count,
          likeCount: doc.data().curr_like_count,
          commentCount: doc.data().curr_comment_count,
        };

        // Store the initial stats in the object
        initialStats[videoId] = {
          viewCount: doc.data().init_view_count,
          likeCount: doc.data().init_like_count,
          commentCount: doc.data().init_comment_count,
        };
      });

      setDoneVideos(doneVideoIds);
      setVideoStats(stats);  // Update the state with current video stats
      setInitialVideoStats(initialStats);  // Update the state with initial video stats
    });

    registerListener(unsubscribe);

    return () => {
      unsubscribe();
      document.removeEventListener('click', handleClickOutside);
    };
  };

  fetchVideoData();
}, [showActionMenu]);






  const [insufficientTime, setInsufficientTime] = useState(false);

  useEffect((videoId) => {
    const user = auth.currentUser;
    const userDocRef = doc(db, 'users', user.uid);
    const insufficientTimeRef = doc(userDocRef, 'subscriptionData', 'noTimeAvailable'); // Make sure this is consistent
  
    const unsubscribe = onSnapshot(insufficientTimeRef, (docSnap) => {
      if (docSnap.exists()) {
        setInsufficientTime(docSnap.data().insufficientTime);
        if (docSnap.data().insufficientTime) {
          // If insufficientTime is true, clear the video from the cache
          localStorage.removeItem('processingVideos');
          setProcessingVideos([]);

        }
      }
    });
  
    registerListener(unsubscribe);  // Register the unsubscribe function
  
    return () => {
      unsubscribe(); // Cleanup
      // console.log("Successfully unsubscribed from insufficientTime listener");  // Log message confirming unsubscription
    };
  }, []);



  const [processingVideos, setProcessingVideos] = useState([]);
  const [videoFailureStatus, setVideoFailureStatus] = useState({});
  const [noCaptionsHere, setnoCaptionsHere] = useState((false));
  const [noAuthAllowed, setnoAuthAllowed] = useState((false));



  const handleVideoProcessing = async (videoId, durationInSeconds, thumbnailUrl, title) => {
    const processingEndTime = Date.now() + 180000; // Current time + 60 seconds
    const newProcessingVideo = { videoId, processingEndTime };
  
    // Add to processingVideos state
    setProcessingVideos(prev => [...prev, videoId]);
    // Cache in localStorage
    const cachedVideos = JSON.parse(localStorage.getItem('processingVideos') || '[]');
    localStorage.setItem('processingVideos', JSON.stringify([...cachedVideos, newProcessingVideo]));
  
    // Proceed with video processing
    getYoutubeCaptionsPython(videoId, durationInSeconds, thumbnailUrl, title, false)
      .then(({ is503Error, isunAuthError }) => { // Destructure the resolved object
        if (is503Error) {
          // Handle the 503 error specifically
          setnoCaptionsHere(true);
  
          // Mark the video as failed immediately without waiting for the timeout
          setProcessingVideos(prev => prev.filter(vId => vId !== videoId));
          clearVideoFromCache(videoId);
          setVideoFailureStatus(prevStatus => ({
            ...prevStatus,
            [videoId]: true,
          }));
  
          // Optionally, you can clear the error message after a delay
          setTimeout(() => {
            setVideoFailureStatus(prevStatus => ({
              ...prevStatus,
              [videoId]: false,
            }));
          }, 20000);
        } else if (isunAuthError) {
          setnoAuthAllowed(true);
  
          // Mark the video as failed immediately without waiting for the timeout
          setProcessingVideos(prev => prev.filter(vId => vId !== videoId));
          clearVideoFromCache(videoId);
          setVideoFailureStatus(prevStatus => ({
            ...prevStatus,
            [videoId]: true,
          }));
  
          // Optionally, you can clear the error message after a delay
          setTimeout(() => {
            setVideoFailureStatus(prevStatus => ({
              ...prevStatus,
              [videoId]: false,
            }));
          }, 20000);
        } else {
          // No 503 error, proceed with normal post-processing
          setTimeout(() => {
            setProcessingVideos(prev => prev.filter(vId => vId !== videoId));
            clearVideoFromCache(videoId);
  
            // Mark the video as failed
            setVideoFailureStatus(prevStatus => ({
              ...prevStatus,
              [videoId]: true,
            }));
  
            // Clear the error message after 20 seconds
            setTimeout(() => {
              setVideoFailureStatus(prevStatus => ({
                ...prevStatus,
                [videoId]: false,
              }));
            }, 20000); // 20 seconds
          }, 180000); // 120 seconds
        }
      })
      .catch(error => {
        // Handle any errors not related to the 503 error
        setProcessingVideos(prev => prev.filter(vId => vId !== videoId));
        clearVideoFromCache(videoId);
      });
  };


  
  const clearVideoFromCache = (videoId) => {
    const cachedVideos = JSON.parse(localStorage.getItem('processingVideos') || '[]');
    const updatedCache = cachedVideos.filter(v => v.videoId !== videoId);
    localStorage.setItem('processingVideos', JSON.stringify(updatedCache));
  };
  
  useEffect(() => {
    const restoreProcessingState = () => {
      const cachedVideos = JSON.parse(localStorage.getItem('processingVideos') || '[]');
      const now = Date.now();
  
      cachedVideos.forEach(({ videoId, processingEndTime }) => {
        if (processingEndTime > now) {
          // If the processing end time hasn't passed, add back to processing state
          setProcessingVideos(prev => [...prev, videoId]);
          // Re-initiate the timeout to clear the processing state and cache
          setTimeout(() => {
            setProcessingVideos(prev => prev.filter(vId => vId !== videoId));
            clearVideoFromCache(videoId);
          }, processingEndTime - now);
        } else {
          // If the processing end time has passed, clear from cache
          clearVideoFromCache(videoId);
        }
      });
    };
  
    restoreProcessingState();
  }, []);
  
  



const loadingMessages = [
  "Fetching Your Videos...",
  "Analyzing Video Metadata...",
  "Hunting for Easter Eggs in Videos...",
  "Getting Lost in a YouTube Rabbit Hole...",
  "Preparing to Go Viral...",
];

// eslint-disable-next-line
const getRandomMessage = () => {
  const randomIndex = Math.floor(Math.random() * loadingMessages.length);
  return loadingMessages[randomIndex];
};


const [currentMessage, setCurrentMessage] = useState(getRandomMessage());

useEffect(() => {
  const intervalId = setInterval(() => {
    setCurrentMessage(getRandomMessage());
  }, 3000); // Change message every 3 seconds

  return () => {
    clearInterval(intervalId);
  };
}, [getRandomMessage]);


const handleConnectYouTube = () => {
  const user = auth.currentUser;
  const userId = user ? user.uid : null;

  const connectYoutubeUrl = `${process.env.REACT_APP_FUNCTIONS_ENDPOINT}/connectYoutube`;

  if (userId) {
    const authUrl = `https://accounts.google.com/o/oauth2/auth?client_id=${CLIENT_ID}&redirect_uri=${encodeURIComponent(connectYoutubeUrl)}&scope=${encodeURIComponent(SCOPES)}&response_type=code&access_type=offline&state=${userId}`;
    window.location.href = authUrl;
  } else {
    // console.error("User not authenticated");
  }
};



const [isHovering, setIsHovering] = useState(false);

const renderTitle = (title) => {
  // Decode the title to handle special characters
  const decodedTitle = he.decode(title);

  // Shorten the title if it's longer than 52 characters, appending '...' if shortened
  return decodedTitle.length > 37 ? `${decodedTitle.substring(0, 37)}...` : decodedTitle;
};

const formatStatNumber = (num) => {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + 'M';
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + 'K';
  } else {
    return num;
  }
};



return (
  <div className="thumbnail-layout">
    {isLoading ? (
      <div className="creator-loading-indicator">
        <div className="loading-content-wrapper">
          <img src={processlogo} alt="----" className="creator-processing-image" />
          <span className="creator-loading-text">{currentMessage}</span>
        </div>
        <div>
          <LoadingBar color="#4add80" progress={progress} />
        </div>
      </div>
    ) : (
      <div className="thumbnail-grid fade-in" key={`videos-${videos.length}`}>
        {accessToken !== null && videos.map((video, index) => (
        <div key={index} className="videocontent-container">
          <div className="thumbnail-wrapper">
            <div className="thumbnail-item">
              <div className="duration-badge">
                {formatDuration(video.durationInSeconds)}
              </div>
              <div className={
                doneVideos.includes(video.videoId) ? "timestamp-badge done" :
                processingVideos.includes(video.videoId) && !insufficientTime ? "timestamp-badge in-queue" :
                "timestamp-badge"
              }>
                <img src={bumpupsBadge} alt="Timestamp Badge" />
              </div>
              <img src={video.thumbnailUrl} alt="Thumbnail" />
              {processingVideos.includes(video.videoId) && !insufficientTime && (
                <div className="processing-overlay"></div>
              )}
              {doneVideos.includes(video.videoId) && (
                <Link to={`/video/${video.videoId}`}>
                  <div className="done-video-overlay"></div>
                </Link>
              )}
              {!doneVideos.includes(video.videoId) && (
                <div className="undone-video-overlay"></div>
              )}

              {videoFailureStatus[video.videoId] && 
                !doneVideos.includes(video.videoId) && (
                  <div className="error-overlay">
                    <div className="error-message-box">
                      {noCaptionsHere ? (
                        <div className="error-message">Captions are not available for this video! 🚫</div>
                      ) : noAuthAllowed ? (
                        <div className="error-message">User has not allowed full YouTube access, unable to process! 🔒</div>
                      ) : (
                        <div className="error-message">Processing taking longer than usual... ⏳</div>
                      )}
                      <div className="retry-message">
                        Learn more <a href="https://intercom.help/bumpups/en/articles/9126173-why-won-t-my-videos-process" target="_blank" rel="noopener noreferrer">here</a>.
                      </div>
                    </div>
                  </div>
                )}
              <a href={`https://www.youtube.com/watch?v=${video.videoId}`} target="_blank" rel="noopener noreferrer" className="youtube-link-button">
                <img src={doneVideos.includes(video.videoId) ? utubeLogoon : utubeLogo} alt="YouTube Link" className="youtube-link-icon" />
              </a>
            </div>

    <div className="info-action-wrapper">
      <div className="info-rectangle">
        <div className="video-title">
          {renderTitle(video.title)}
        </div>
        <div className="action-buttons videolayout">
          {!doneVideos.includes(video.videoId) && (
            (processingVideos.includes(video.videoId) && !insufficientTime) ? 
              <button className="icon-button processing">
                <img src={processlogo} alt="Processing" className="icon" />
              </button> :
              <button className="icon-button not-done" onClick={async () => {
            await handleVideoProcessing(video.videoId, video.durationInSeconds, video.thumbnailUrl, video.title);
          }}>
            <img src={processVideoIcon} alt="Process" className="icon" />
            <span className="process-tooltip">Process Video</span>
          </button>
          )}
          {doneVideos.includes(video.videoId) && (
            <Link to={`/video/${video.videoId}`} className="icon-button done">
              <img src={viewVideoValue} alt="More" className="icon" />
            </Link>
          )}
        </div>
      </div>
    </div>
  </div>

  
          <div className="video-stats-container">
          <div className={`video-stats ${doneVideos.includes(video.videoId) && videoStats[video.videoId]?.viewCount != null && videoStats[video.videoId]?.likeCount != null && videoStats[video.videoId]?.commentCount != null ? '' : 'dimmed'}`}>
            <div className="video-stat">
              <div className="stat-icon">Views</div>
              <div className={`stat-box ${doneVideos.includes(video.videoId) && videoStats[video.videoId]?.viewCount != null && videoStats[video.videoId]?.viewCount !== initialVideoStats[video.videoId]?.viewCount ? 'number' : 'default'}`}>
                {doneVideos.includes(video.videoId) && videoStats[video.videoId]?.viewCount != null
                  ? <>
                      {formatStatNumber(videoStats[video.videoId].viewCount)}
                      {videoStats[video.videoId]?.viewCount > initialVideoStats[video.videoId]?.viewCount && (
                        <span className="increase">+{formatStatNumber(videoStats[video.videoId].viewCount - initialVideoStats[video.videoId].viewCount)}</span>
                      )}
                    </>
                  : '--'}
              </div>
            </div>
            <div className="video-stat">
              <div className="stat-icon">Likes</div>
              <div className={`stat-box ${doneVideos.includes(video.videoId) && videoStats[video.videoId]?.likeCount != null && videoStats[video.videoId]?.likeCount !== initialVideoStats[video.videoId]?.likeCount ? 'number' : 'default'}`}>
                {doneVideos.includes(video.videoId) && videoStats[video.videoId]?.likeCount != null
                  ? <>
                      {formatStatNumber(videoStats[video.videoId].likeCount)}
                      {videoStats[video.videoId]?.likeCount > initialVideoStats[video.videoId]?.likeCount && (
                        <span className="increase">+{formatStatNumber(videoStats[video.videoId].likeCount - initialVideoStats[video.videoId].likeCount)}</span>
                      )}
                    </>
                  : '--'}
              </div>
            </div>
            <div className="video-stat">
              <div className="stat-icon">Comments</div>
              <div className={`stat-box ${doneVideos.includes(video.videoId) && videoStats[video.videoId]?.commentCount != null && videoStats[video.videoId]?.commentCount !== initialVideoStats[video.videoId]?.commentCount ? 'number' : 'default'}`}>
                {doneVideos.includes(video.videoId) && videoStats[video.videoId]?.commentCount != null
                  ? <>
                      {formatStatNumber(videoStats[video.videoId].commentCount)}
                      {videoStats[video.videoId]?.commentCount > initialVideoStats[video.videoId]?.commentCount && (
                        <span className="increase">+{formatStatNumber(videoStats[video.videoId].commentCount - initialVideoStats[video.videoId].commentCount)}</span>
                      )}
                    </>
                  : '--'}
              </div>
            </div>
          </div>
        </div>
                </div>
        ))}
      </div>
    )}

    {!isLoadingPreview && accessToken === null && (
      <div>
        <div className="creator-noaccess-preview">
          <div className="content-wrapper">
            <div className="creator-noaccess-image">
              <img 
                src={isHovering ? noAccessImageActive : noAccessImageDefault} 
                alt="Descriptive Text" 
              />
            </div>
            <div 
              className="creator-noaccess-button-wrapper"
              onMouseEnter={() => setIsHovering(true)}
              onMouseLeave={() => setIsHovering(false)}
            >
              <button className="creator-noaccess-button" onClick={handleConnectYouTube}>
                Connect YouTube
              </button>
            </div>
          </div>
        </div>
  
        <PreviewPoints />

        <div className="guidance-text-unauth">
          Don't want to connect your YouTube channel? Start processing right away <Link to="/links">here</Link>
        </div>
      </div>
    )}

    {accessToken && (
      <>
        {!isContentAvailable && !isLoading && !isVideoSearched && !noVideosMade && (
          <div className="needcatalogwrapper">
            <button className="fetchCatalogButton" onClick={handleFetchVideos}>
              <img src={bumpupSmall} className="buttonImage" alt="Bump Up Icon" />
              Fetch Videos
            </button>
          </div>
        )}

        {noVideosMade && (
          <div className="no-videos-container">
            <img src={bumpupSmall} alt="No Videos" className="no-videos-image" />
            <div className="no-videos-message">
              No videos were found for the selected period.
            </div>
          </div>
        )}

        <div className="guidance-text">
          Wrong timestamps, incorrect words, or need assistance? Check out our guides <a href="https://intercom.help/bumpups/en/collections/7956856-uploading-your-video" target="_blank" rel="noopener noreferrer">here</a>.
        </div>
      </>
    )}
  </div>
);






};

export default ThumbnailLayout;