
import React, { useContext, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './Navbar.css';

//FIREBASE
import { auth, db } from '../firebase';
import { doc, onSnapshot, getDoc } from 'firebase/firestore';

//FUNCTIONS
import { AuthContext } from '../AuthContext';
import { unregisterAllListeners } from './ListenerManager';

//IMPORT UI
import PlanPopUp from '../myPlans/PlanPopUp';
import PaidUserPlanPopUp from '../myPlans/PaidUserPlanPopUp';

//IMAGES
import singinlogo from '../assets/images/signin-v2.png';
import pricinglogo from '../assets/images/v2-pricing.png';
import bumpupsLogo from '../assets/images/bumpups-dark-small.jpg';
import subicon from '../assets/images/v2-plan.png';
import logouticon from '../assets/images/v2-logout.png';
import placeholderImage from '../assets/images/placeholder-load.jpg';
import brandicon from '../assets/images/v2-settings.png';

import basePlanLogo from '../assets/images/baseplan-white.png';
import bumpPlanLogo from '../assets/images/bumpups-white.png';



function Navbar({ isSidebarOpen, toggleSidebar }) {
  const [showMenu, setShowMenu] = useState(false);
    const [showProfileMenu, setShowProfileMenu] = useState(false);  // New state variable
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    
    const navigate = useNavigate();

    const { currentUser } = useContext(AuthContext);

    const [timeBank, setTimeBank] = useState(0); // State to store the timeBank value
    const [timeBankBump, setTimeBankBump] = useState(0); // Always fetch timeBankBump directly

    const [showPlanPopUp, setShowPlanPopUp] = useState(false);

    const [profileImage, setProfileImage] = useState();



    const handleSignIn = () => {
        navigate('/signin');
    };

    const handlePricepage = () => {
      navigate('/pricing');
  };


    const handleSignUp = () => {
      navigate('/signup');
  };

  const handleLogout = async () => {
    try {
      unregisterAllListeners(); 
        await auth.signOut();
      //  console.log("Successfully unsubscribed all listeners");  // Optional: Log message confirming unsubscription of all listeners

        navigate('/');
    } catch (error) {
        // console.error("Error logging out:", error);
    }
};

    useEffect(() => {
      const handleClickOutside = (event) => {
          if (showProfileMenu && !event.target.closest('.profile-dropdown') && !event.target.closest('.profile-icon')) {
              setShowProfileMenu(false);
          }
      };
      document.addEventListener('click', handleClickOutside);
      return () => document.removeEventListener('click', handleClickOutside);
  }, [showProfileMenu]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
  
    window.addEventListener('resize', handleResize);
  
    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  const user = auth.currentUser;

  // Initialize from sessionStorage or default values
  const [currentPlan, setCurrentPlan] = useState(
    sessionStorage.getItem(`currentPlan_${user?.uid}`) || "Free Plan"
  );
  const [isActive, setIsActive] = useState(
    sessionStorage.getItem(`isActive_${user?.uid}`) ? JSON.parse(sessionStorage.getItem(`isActive_${user?.uid}`)) : false
  );



  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const userDocRef = doc(db, "users", user.uid);
      const subscriptionDocRef = doc(userDocRef, 'subscriptionData', 'timeWarden');
      const planDetailsRef = doc(userDocRef, "subscriptionData", "planDetails");
  
      // Listen for real-time updates to timeBank and timeBankBump
      const unsubscribeTime = onSnapshot(subscriptionDocRef, (docSnap) => {
        if (docSnap.exists()) {
          const newTimeBank = docSnap.data().timeBank || 0;
          const newTimeBankBump = docSnap.data().timeBankBump || 0;
  
          const sessionTimeBank = sessionStorage.getItem(`timeBank_${user.uid}`);
          const sessionTimeBankBump = sessionStorage.getItem(`timeBankBump_${user.uid}`);
  
          if (!sessionTimeBank || parseInt(sessionTimeBank, 10) !== newTimeBank) {
            setTimeBank(newTimeBank);
            sessionStorage.setItem(`timeBank_${user.uid}`, newTimeBank.toString());
          }
  
          if (!sessionTimeBankBump || parseInt(sessionTimeBankBump, 10) !== newTimeBankBump) {
            setTimeBankBump(newTimeBankBump);
            sessionStorage.setItem(`timeBankBump_${user.uid}`, newTimeBankBump.toString());
          }
        }
      });
  
      // Listen for real-time updates to plan details
      const unsubscribePlan = onSnapshot(planDetailsRef, (docSnap) => {
        if (docSnap.exists()) {
          const newPlanData = docSnap.data();
          const newPlan = newPlanData.currentPlan || 'Free Plan';
          const newActiveStatus = newPlanData.IsActive !== undefined ? newPlanData.IsActive : false;
  
          setCurrentPlan(newPlan);
          setIsActive(newActiveStatus);
          sessionStorage.setItem(`currentPlan_${user.uid}`, newPlan);
          sessionStorage.setItem(`isActive_${user.uid}`, JSON.stringify(newActiveStatus));
        }
      });
  
      // Fetch initial data from sessionStorage or Firestore
      const fetchData = async () => {
        const sessionTimeBank = sessionStorage.getItem(`timeBank_${user.uid}`);
        const sessionTimeBankBump = sessionStorage.getItem(`timeBankBump_${user.uid}`);
        const sessionCurrentPlan = sessionStorage.getItem(`currentPlan_${user.uid}`);
        const sessionIsActive = sessionStorage.getItem(`isActive_${user.uid}`);
  
        if (sessionTimeBank !== null) {
          setTimeBank(parseInt(sessionTimeBank, 10));
        } else {
          const docSnap = await getDoc(subscriptionDocRef);
          if (docSnap.exists()) {
            const timeBank = docSnap.data().timeBank || 0;
            setTimeBank(timeBank);
            sessionStorage.setItem(`timeBank_${user.uid}`, timeBank.toString());
          }
        }
  
        if (sessionTimeBankBump !== null) {
          setTimeBankBump(parseInt(sessionTimeBankBump, 10));
        } else {
          const docSnap = await getDoc(subscriptionDocRef);
          if (docSnap.exists()) {
            const timeBankBump = docSnap.data().timeBankBump || 0;
            setTimeBankBump(timeBankBump);
            sessionStorage.setItem(`timeBankBump_${user.uid}`, timeBankBump.toString());
          }
        }
  
        if (sessionCurrentPlan !== null && sessionIsActive !== null) {
          setCurrentPlan(sessionCurrentPlan);
          setIsActive(JSON.parse(sessionIsActive));
        } else {
          const planSnap = await getDoc(planDetailsRef);
          if (planSnap.exists()) {
            const planData = planSnap.data();
            const plan = planData.currentPlan || 'Free Plan';
            const activeStatus = planData.IsActive !== undefined ? planData.IsActive : false;
  
            setCurrentPlan(plan);
            setIsActive(activeStatus);
            sessionStorage.setItem(`currentPlan_${user.uid}`, plan);
            sessionStorage.setItem(`isActive_${user.uid}`, JSON.stringify(activeStatus));
          }
        }
      };
  
      fetchData();
  
      return () => {
        unsubscribeTime();
        unsubscribePlan();
      };
    }
  }, [currentUser]);  
        
        
        
        


        
        useEffect(() => {
          let unsubscribe;
        
          const fetchUserProfileImage = () => {
            if (currentUser) { // Check if currentUser is not null
              // Attempt to retrieve the profile image from localStorage
              const storedImage = sessionStorage.getItem(`profileImage_${currentUser.uid}`);
              if (storedImage) {
                // console.log("Loading profile image from localStorage for user:", currentUser.uid); // Log for localStorage retrieval
                setProfileImage(storedImage);
              } else {
                // Subscribe to the user's document if the image isn't in localStorage
                const userDocRef = doc(db, 'users', currentUser.uid, 'userSettings', 'profile');
        
                unsubscribe = onSnapshot(userDocRef, (doc) => {
                  if (doc.exists()) {
                    const profileImage = doc.data().profileImage || '';
                    setProfileImage(profileImage);
                    // Store the profile image in localStorage for future access
                    sessionStorage.setItem(`profileImage_${currentUser.uid}`, profileImage);
                    // console.log("Profile image fetched from Firestore and stored in localStorage for user:", currentUser.uid); // Log for Firestore retrieval
                  } else {
                    // console.log("No profile image found in Firestore for user:", currentUser.uid); // Log when no image is found in Firestore
                  }
                });
              }
            } else {
              // console.log("No current user found."); // Log when there is no current user
            }
          };
        
          fetchUserProfileImage();
        
          return () => {
            if (unsubscribe) {
              unsubscribe();
              // console.log("Unsubscribed from Firestore listener for profile image."); // Log for unsubscribing the listener
            }
          };
        }, [currentUser]);
        
        

        

    // Utility function to convert minutes into "Xh Xm" format
    const formatTime = (minutes) => {
      const h = Math.floor(minutes / 60);
      const m = minutes % 60;
      return `${h}h ${m}m`;
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showMenu && !event.target.closest('.menu-dropdown') && !event.target.closest('.menu-toggle')) {
        setShowMenu(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [showMenu]);


  const location = useLocation();
  const { pathname } = location;

  // Determine if the current route should display the logged-in UI
  const loggedinUI = ['/creator', '/dashboard', '/plans', '/settings'].includes(pathname) || currentUser;
  
    // Determine if the sidebar toggle button should be displayed
  const showSidebarToggle = loggedinUI && pathname !== '/pricing';

  const navbarClass = loggedinUI ? 'navbar' : 'navbar navbar-sticky';


  return (
    <>
    <div className={loggedinUI ? '' : 'sticky-header-wrapper'}>
      {!loggedinUI && (
        <div className="announcement-bar">
          <p className="announcement-bar-text">
            We’re thrilled to share that Bumpups has raised funding from Google to advance AI for content creation! <span className="emoji">🎉</span>
          </p>
        </div>
      )}

    <nav className={navbarClass}>
      <div className="brand-and-toggle-container">
      {showSidebarToggle && (
          <button onClick={toggleSidebar} className="sidebar-toggle" title={isSidebarOpen ? "Close Sidebar" : "Open Sidebar"}>
            {isSidebarOpen ? '✖' : '☰'}
          </button>
        )}
        <Link to={currentUser ? "/dashboard" : "/"} className="navbar-brand">
          <img src={bumpupsLogo} alt="Bumpups Logo" className="navbar-logo" />
          <span className="nav-bumpups-brand">bump</span>
          <span className="bumpups-brand-color">ups</span>
        </Link>
      </div>
      <div className="navbar-links">
        {loggedinUI ? (
          <>
            {windowWidth <= 1280 ? null : (
              <>
                  <Link to="/plans" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <div className="time-container">
                      <img src={basePlanLogo} alt=">" className="planNavIcon info-icon" />
                      <span className="base-time-text">{formatTime(timeBank)}</span>
                      <div className="navbar-tooltip-text">Base credits</div>
                    </div>
                  </Link>

                  <Link to="/plans" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <div className="time-container">
                      <img src={bumpPlanLogo} alt=">" className="planNavIcon info-icon" />
                      <span className="bump-time-text">{formatTime(timeBankBump)}</span>
                      <div className="navbar-tooltip-text">Bump credits</div>
                    </div>
                  </Link>
                <button className="add-credits-button" onClick={() => setShowPlanPopUp(true)}>
                  Add more credits
                </button>
              </>
            )}
              {showPlanPopUp && (
                (currentPlan === "base" || currentPlan === "Essential Plan" || currentPlan === "bump") && isActive ?
                  <PaidUserPlanPopUp 
                    isOpen={showPlanPopUp} 
                    onClose={() => setShowPlanPopUp(false)} 
                    currentPlan={currentPlan} // Pass currentPlan as a prop
                  /> :
                  <PlanPopUp 
                    isOpen={showPlanPopUp} 
                    onClose={() => setShowPlanPopUp(false)} 
                    currentPlan={currentPlan} // Pass currentPlan as a prop
                  />
              )}
            <img 
              src={profileImage || placeholderImage} 
              alt="Profile" 
              className="profile-icon" 
              onClick={() => setShowProfileMenu(!showProfileMenu)}
            />
            {showProfileMenu && (
              <div className="profile-dropdown active">
                <div className="nav-button" style={{ textAlign: 'left' }} onClick={() => setShowProfileMenu(false)}>
                <Link to="/plans" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <img src={subicon} alt="Subscription Icon" className="iconmenu" />
                    My plan
                  </Link>
                </div>
                <div className="nav-button" style={{ textAlign: 'left' }} onClick={() => setShowProfileMenu(false)}>
                  <Link to="/settings" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <img src={brandicon} alt="Settings Icon" className="iconmenu" />
                    Settings & Beta
                  </Link>
                </div>
                <div className="divider"></div>
                <div className="nav-button" style={{ textAlign: 'left' }} onClick={() => { handleLogout(); setShowProfileMenu(false); }}>
                  <span style={{ textDecoration: 'none', color: 'inherit' }}>
                    <img src={logouticon} alt="Logout Icon" className="iconmenu" />
                    Log out
                  </span>
                </div>
              </div>
            )}   
          </>
        ) : (
          <>
            {windowWidth > 1280 && (
              <>
                <button className="nav-button" onClick={handlePricepage}>Pricing</button>
                <button className="nav-button" onClick={handleSignIn}>Log in</button>
                <button className="nav-button signup" onClick={handleSignUp}>Sign up - It's Free</button>
              </>
            )}
            {windowWidth <= 1280 && (
              <>
                <button className="nav-button menu-toggle" onClick={() => setShowMenu(!showMenu)}>
                  {showMenu ? '✖' : '☰'}
                </button>
                {showMenu && (
                  <div className="menu-dropdown active">
                    <div className="nav-button" style={{ textAlign: 'left' }}>
                      <Link to="/signin" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <img src={pricinglogo} alt=">" className="iconmenu" />
                      </Link>
                      <Link to="/pricing" style={{ textDecoration: 'none', color: 'inherit' }}>Pricing</Link>
                    </div>
                    <div className="nav-button" style={{ textAlign: 'left' }}>
                      <Link to="/signin" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <img src={singinlogo} alt=">" className="iconmenu" />
                      </Link>
                      <Link to="/signin" style={{ textDecoration: 'none', color: 'inherit' }}>Log In</Link>
                    </div>
                    <button className="nav-button signup" onClick={handleSignUp}>Sign up - It's Free</button>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </nav>
    </div>
    </>

  );



}

export default Navbar;
