import React, { useState, useEffect, useRef } from 'react';
import { Routes, Route, useNavigate, Link, useLocation } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Helmet } from 'react-helmet';
import './UserSettings.css';

//FIREBASE
import { db, auth } from '../firebase';
import { doc, updateDoc, getDoc, setDoc, onSnapshot } from 'firebase/firestore';
import { storage } from '../firebase'; 
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

//FUNCTIONS
import { CLIENT_ID, SCOPES } from '../googleOAuth';
import AppTransition from '../components/AppTransition';
import { debounce } from 'lodash';

//IMPORT UI
import BrandTemplate from './BrandTemplate';
import SubtitleTemplate from './SubtitleTemplate';
import Sidebar from '../myDashboard/Sidebar';
import CloseSidebar from '../myDashboard/CloseSidebar';

//IMAGES
import utubeDisconnect from '../assets/images/utube-dark.png';
import utubeLogoConnected from '../assets/images/utube-red.png';
import generalIcon from '../assets/images/v2-settings.png';
import creatorIcon from '../assets/images/v2-creator.png';
import timestampIcon from '../assets/images/timestamp-v2.png';
import utubeIcon from '../assets/images/v2-creator.png';
import commentIcon from '../assets/images/comment-v2.png';
import subtitleIcon from '../assets/images/subtitle-v2.png';
import planIcon from '../assets/images/v2-plan.png';
import profileImageplace from '../assets/images/placeholder-load.jpg';
import updateButtonImage from '../assets/images/upload-v1.png';
import revokeLogo from '../assets/images/x-v2.png';
import emojiIcon from '../assets/images/emoji-v2.png';
// import languageIcon from '../assets/images/language-v2.png';
import stamplengthIcon from '../assets/images/linelength-v1.png';
import brandingLogo from '../assets/images/brand-white-v1.png';
import infoLogo from '../assets/images/info-v1.png';
import bumpupsLogo from '../assets/images/bumpups-dark-small.jpg';
import bumpUPlogo from '../assets/images/bumparrow.png';



const UserSettings = ({ isSidebarOpen, toggleSidebar }) => {
    const fileInputRef = useRef(null);

    const [isConnected, setIsConnected] = useState(false);
    const [showBrandTemplate, setShowBrandTemplate] = useState(false);
    const [showSubtitleTemplate, setShowSubtitleTemplate] = useState(false);
    const [isToggledsubtitles] = useState(false);

    const navigate = useNavigate();

    // Optimized Storage
    const user = auth.currentUser;
    let userData = {};

    if (user) {
        const localStorageUserData = localStorage.getItem(`userData_${user.uid}`);
        if (localStorageUserData) {
            const parsedData = JSON.parse(localStorageUserData);
            // Verify the UID matches before using the data
            if (parsedData.uid === user.uid) {
                userData = parsedData;
            }
        }
    }

    // Initialize state with localStorage values if they exist, or set default values
    const [profileImage, setProfileImage] = useState(userData.profileImage || profileImageplace);
    const [email, setEmail] = useState(userData.email || '');
    const [currentPlan, setCurrentPlan] = useState(userData.currentPlan || "Loading...");
    const [isToggledcomment, setIsToggledcomment] = useState(userData.isAutoCommentEnabled !== undefined ? userData.isAutoCommentEnabled : true);
    const [isShortTimestamps, setIsShortTimestamps] = useState(userData.isShortTimestamps !== undefined ? userData.isShortTimestamps : false);

    const manageComments = () => {
        setShowBrandTemplate(!showBrandTemplate);
    };

    const manageSubtitles = () => {
        setShowSubtitleTemplate(!showSubtitleTemplate);
    };

    const handleTabClick = (tab) => {
        if (tab === 'General') {
            navigate('/settings');
        } else {
            navigate(`/settings/${tab.toLowerCase()}`);
        }
    };

    const debouncedCommentHandleToggle = debounce(async (newValue, setIsToggledcomment) => {
        setIsToggledcomment(newValue);

        const user = auth.currentUser;
        if (user) {
            const userSettingsRef = doc(db, "users", user.uid, "autoSettings", "youtubeToggles");
            try {
                await updateDoc(userSettingsRef, {
                    isAutoCommentEnabled: newValue
                });
            } catch (error) {
                // Handle error as necessary
            }
        }
    }, 500);

    const handleCommentToggleChange = () => {
        const newValue = !isToggledcomment;
        debouncedCommentHandleToggle(newValue, setIsToggledcomment);
    };

    const handleConnectYouTube = () => {
        const user = auth.currentUser;
        const userId = user ? user.uid : null;

        const connectYoutubeUrl = `${process.env.REACT_APP_FUNCTIONS_ENDPOINT}/connectYoutube`

        if (userId) {
            const authUrl = `https://accounts.google.com/o/oauth2/auth?client_id=${CLIENT_ID}&redirect_uri=${encodeURIComponent(connectYoutubeUrl)}&scope=${encodeURIComponent(SCOPES)}&response_type=code&access_type=offline&state=${userId}`;
            window.location.href = authUrl;
        } else {
            // Handle the case when the user is not authenticated
        }
    };

    useEffect(() => {
        const user = auth.currentUser;
        if (user) {
            const userDocRef = doc(db, 'users', user.uid);

            const localStorageUserData = localStorage.getItem(`userData_${user.uid}`);
            if (localStorageUserData) {
                const userData = JSON.parse(localStorageUserData);
                setProfileImage(userData.profileImage || profileImageplace);
                setEmail(userData.email || '');
                setCurrentPlan(userData.currentPlan || 'Free Plan');
                setIsToggledcomment(userData.isAutoCommentEnabled !== undefined ? userData.isAutoCommentEnabled : true);
                setIsShortTimestamps(userData.isShortTimestamps !== undefined ? userData.isShortTimestamps : false);
            }

            const unsubscribeUser = onSnapshot(userDocRef, (userDocSnapshot) => {
                if (userDocSnapshot.exists()) {
                    const userData = userDocSnapshot.data();
                    setEmail(userData.email || '');

                    localStorage.setItem(`userData_${user.uid}`, JSON.stringify(userData));
                }
            });

            const userProfileImageRef = doc(db, 'users', user.uid, 'userSettings', 'profile');
            const unsubscribeProfileImage = onSnapshot(userProfileImageRef, (profileSnapshot) => {
                if (profileSnapshot.exists()) {
                    const profileData = profileSnapshot.data();
                    setProfileImage(profileData.profileImage || profileImageplace);

                    localStorage.setItem(`userProfileImage_${user.uid}`, profileData.profileImage || profileImageplace);
                }
            });

            const planDetailsRef = doc(userDocRef, "subscriptionData", "planDetails");
            const unsubscribePlanDetails = onSnapshot(planDetailsRef, (planDetailsSnap) => {
                if (planDetailsSnap.exists()) {
                    setCurrentPlan(planDetailsSnap.data().currentPlan);
                }
            });

            const userCreatorSettingsRef = doc(userDocRef, "autoSettings", "youtubeToggles");
            const unsubscribeCreatorSettings = onSnapshot(userCreatorSettingsRef, (creatorSettingsSnap) => {
                if (creatorSettingsSnap.exists()) {
                    setIsToggledcomment(creatorSettingsSnap.data().isAutoCommentEnabled);
                } else {
                    setDoc(userCreatorSettingsRef, { isAutoCommentEnabled: true }, { merge: true });
                    setIsToggledcomment(true);
                }
            });

            const timestampProfileRef = doc(userDocRef, 'valueSettings', 'timestamps');
            const unsubscribeTimestamps = onSnapshot(timestampProfileRef, (doc) => {
                if (doc.exists()) {
                    const { isShortTimestamps } = doc.data();
                    setIsShortTimestamps(isShortTimestamps || false);
                    const sessionStorageData = JSON.parse(sessionStorage.getItem(`userData_${user.uid}`)) || {};
                    sessionStorage.setItem(`userData_${user.uid}`, JSON.stringify({ ...sessionStorageData, isShortTimestamps }));
                }
            });

            return () => {
                unsubscribeUser();
                unsubscribePlanDetails();
                unsubscribeCreatorSettings();
                unsubscribeProfileImage();
                unsubscribeTimestamps();
            };
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const user = auth.currentUser;
            const userId = user ? user.uid : null;

            if (userId) {
                const userDoc = doc(db, "users", userId, 'contentAccess', 'youtubeAccess');
                const docSnap = await getDoc(userDoc);

                if (docSnap.exists()) {
                    const userData = docSnap.data();
                    const channelId = userData.channelId;
                    const accessToken = userData.accessToken;

                    if (channelId && accessToken) {
                        setIsConnected(true);
                    }
                }
            }
        };

        fetchData();
    }, []);

    const handleImageUpload = async (event) => {
        const file = event.target.files[0];
        const user = auth.currentUser;

        if (file && file.size < 1024 * 1024) {
            try {
                const storageRef = ref(storage, `profileImages/${user.uid}`);
                await uploadBytes(storageRef, file);

                const photoURL = await getDownloadURL(storageRef);

                const userDocRef = doc(db, 'users', user.uid, 'userSettings', 'profile');
                await updateDoc(userDocRef, { profileImage: photoURL });

                setProfileImage(photoURL);
                sessionStorage.removeItem(`profileImage_${user.uid}`);
                sessionStorage.setItem(`profileImage_${user.uid}`, photoURL);

                window.location.reload();
            } catch (error) {
                // Handle error
            }
        } else {
            alert("File is too large. Please select a file less than 1MB.");
        }
    };

    const [revokeButtonText, setRevokeButtonText] = useState("Disconnect");

    const handleRevokeYouTubeAction = (channelId) => {
        const user = auth.currentUser;
        const userId = user ? user.uid : null;

        if (userId) {
            const functions = getFunctions();
            const revokeYoutubeCallable = httpsCallable(functions, 'revokeYoutube');
    
            revokeYoutubeCallable()

                .then((response) => {
                    sessionStorage.removeItem('accessToken');
                    const YThandleKey = `${userId}_${channelId}_YThandle`;
                    localStorage.removeItem(YThandleKey);
                    window.location.reload();
                })
                .catch((error) => {
                    if (error.code === 'functions/internal') {
                        // Handle internal server error (500)
                        setRevokeButtonText("Retry");
                    } else {
                        setRevokeButtonText("Retry");
                    }
                });
        }
    };

    const debouncedHandleRevokeYouTube = debounce(handleRevokeYouTubeAction, 10000);

    const handleRevokeClick = () => {
        setRevokeButtonText("Loading...");
        debouncedHandleRevokeYouTube();
    };




    // BUMP SETTINGS
    // BUMP SETTINGS
    // BUMP SETTINGS
    // BUMP SETTINGS
    // BUMP SETTINGS

    const BumpSettings = () => {
        const [description, setDescription] = useState('');
        const [isSaving, setIsSaving] = useState(false);
        const [descriptionSaved, setDescriptionSaved] = useState(false);
        const textareaRef = useRef(null);
    
        useEffect(() => {
            const user = auth.currentUser;
            if (user) {
                const localStorageDescription = localStorage.getItem(`userTemplateDescription_${user.uid}`);
                if (localStorageDescription) {
                    setDescription(localStorageDescription);
                } else {
                    const fetchDescription = async () => {
                        const userDocRef = doc(db, 'users', user.uid, 'userSettings', 'profile');
                        const userDoc = await getDoc(userDocRef);
                        if (userDoc.exists()) {
                            const userData = userDoc.data();
                            if (userData.userTemplateDescription) {
                                setDescription(userData.userTemplateDescription);
                                localStorage.setItem(`userTemplateDescription_${user.uid}`, userData.userTemplateDescription);
                            }
                        }
                    };
                    fetchDescription();
                }
            }
        }, []);
    
        const handleDescriptionChange = (event) => {
            setDescription(event.target.value);
        };
    
        const saveDescriptionTemplate = async () => {
            const user = auth.currentUser;
            const debounceKey = `$${user.uid}_description_debounce`;
            const lastSaveTimestamp = localStorage.getItem(debounceKey);
            const currentTime = Date.now();
    
            if (lastSaveTimestamp && (currentTime - lastSaveTimestamp) < 5000) { // 5 second debounce
                // console.log('Debounce active, please wait before saving again');
                return;
            }
    
            setIsSaving(true); // Disable button and start debounce
            localStorage.setItem(debounceKey, currentTime.toString());
    
            try {
                const userDocRef = doc(db, 'users', user.uid);
                const profileCorrectRef = doc(userDocRef, 'userSettings', 'profile');
                await setDoc(profileCorrectRef, { userTemplateDescription: description }, { merge: true });
                // console.log("Template saved:", description);
                setDescriptionSaved(true); // Set saved status to true on success
                localStorage.setItem(`userTemplateDescription_${user.uid}`, description); // Update local storage
                setTimeout(() => {
                    setDescriptionSaved(false); // Reset saved status after 3 seconds
                }, 3000);
            } catch (error) {
                // console.error("Error saving template:", error);
            }
    
            setTimeout(() => {
                setIsSaving(false); // Reset debounce status after 5 seconds
                localStorage.removeItem(debounceKey);
            }, 5000); // 5 second debounce
        };
    
        const handleDescriptionPaste = (text) => {
            const textarea = textareaRef.current;
            const startPos = textarea.selectionStart;
            const endPos = textarea.selectionEnd;
            const beforeValue = description.substring(0, startPos);
            const afterValue = description.substring(endPos, description.length);
            setDescription(`${beforeValue}${text}${afterValue}`);
            setTimeout(() => {
                textarea.setSelectionRange(startPos + text.length, startPos + text.length);
                textarea.focus();
            }, 0);
        };
    
        return (
            <div className="settingBump-section-input-group">
                <div className="editBump-description-header">
                    <div className="settingBump-section-description-label">YouTube Description</div>
                    <button
                        className="editBump-save-template-button"
                        onClick={saveDescriptionTemplate}
                        disabled={!description.trim() || isSaving} // Disable button during debounce period
                    >
                        {descriptionSaved ? 'Template Saved' : 'Save'}
                    </button>
                </div>
                <div className="editBump-description-wrapper">
                    <textarea
                        ref={textareaRef}
                        className="editBump-section-description"
                        placeholder="This is a template for new video uploads. Use placeholders like {{VIDEO_DESCRIPTION}} to automatically insert the video description generated by Bump AI."
                        value={description}
                        onChange={handleDescriptionChange}
                    />
                </div>
                <div className="editBump-textarea-character-count">{description.length}/5000</div>
                <div className="editBump-button-group">
                    <button
                        className="editBump-save-template-button"
                        onClick={() => handleDescriptionPaste('{{VIDEO_DESCRIPTION}}')}
                    >
                        Description
                    </button>
                    <button
                        className="editBump-save-template-button"
                        onClick={() => handleDescriptionPaste('{{VIDEO_TAKEAWAYS}}')}
                    >
                        Key Takeaways
                    </button>
                    <button
                        className="editBump-save-template-button"
                        onClick={() => handleDescriptionPaste('{{VIDEO_TIMESTAMPS}}')}
                    >
                        Timestamps
                    </button>
                    <button
                        className="editBump-save-template-button"
                        onClick={() => handleDescriptionPaste('{{VIDEO_HASHTAGS}}')}
                    >
                        Hashtags
                    </button>
                </div>
            </div>
        );
    };
    
      
  



    const languages = [
        { value: 'en', label: 'en', name: '🇺🇸 English' }, 
        { value: 'hi', label: 'hi', name: '🇮🇳 Hindi' }, 
        { value: 'es', label: 'es', name: '🇪🇸 Spanish' }, 
        { value: 'pt', label: 'pt', name: '🇵🇹 Portuguese' }, 
        { value: 'ru', label: 'ru', name: '🇷🇺 Russian' }, 
        { value: 'de', label: 'de', name: '🇩🇪 German' }, 
        { value: 'fr', label: 'fr', name: '🇫🇷 French' }, 
        { value: 'ja', label: 'ja', name: '🇯🇵 Japanese' }, 
        { value: 'ko', label: 'ko', name: '🇰🇷 Korean' }, 
        { value: 'ar', label: 'ar', name: '🇸🇦 Arabic' }
      ];
      
      

    const GeneralSettings = () => {


        const [language, setLanguage] = useState('en'); // Default to 'en'
        const [isLanguageChanged, setIsLanguageChanged] = useState(false); // Track if language has been modified
        const selectedLanguage = languages.find(lang => lang.value === language)?.name;


        const [isSaving, setIsSaving] = useState(false);
        const [languageSaved, setLanguageSaved] = useState(false);

        useEffect(() => {
            const fetchUserLanguage = async () => {
                const user = auth.currentUser;
                if (user) {
                    const localStorageLanguage = localStorage.getItem(`userDefaultLanguage_${user.uid}`);
                    if (localStorageLanguage) {
                        setLanguage(localStorageLanguage);
                    } else {
                        const userDocRef = doc(db, 'users', user.uid, 'userSettings', 'profile');
                        const userDoc = await getDoc(userDocRef);
                        if (userDoc.exists()) {
                            const userData = userDoc.data();
                            if (userData.userDefaultLanguage) {
                                setLanguage(userData.userDefaultLanguage);
                                localStorage.setItem(`userDefaultLanguage_${user.uid}`, userData.userDefaultLanguage);
                            }
                        }
                    }
                }
            };
    
            fetchUserLanguage();
        }, []);
    


        const handleLanguageChange = (event) => {
            const newLanguage = event.target.value;
            setLanguage(newLanguage);
            setIsLanguageChanged(newLanguage !== localStorage.getItem(`userDefaultLanguage_${auth.currentUser.uid}`)); // Check if the new language is different from the saved one
        };
    
        const saveLanguageSetting = async () => {
            const user = auth.currentUser;
            const debounceKey = `$${user.uid}_language_debounce`;
            const lastSaveTimestamp = localStorage.getItem(debounceKey);
            const currentTime = Date.now();
    
            if (lastSaveTimestamp && (currentTime - lastSaveTimestamp) < 5000) {
                return; // Debounce active, prevent immediate re-save
            }
    
            setIsSaving(true);
            localStorage.setItem(debounceKey, currentTime.toString());
    
            try {
                const userDocRef = doc(db, 'users', user.uid);
                const profileCorrectRef = doc(userDocRef, 'userSettings', 'profile');
                await setDoc(profileCorrectRef, { userDefaultLanguage: language }, { merge: true });
    
                localStorage.setItem(`userDefaultLanguage_${user.uid}`, language);
                setLanguageSaved(true);
                setIsLanguageChanged(false); // Reset the change tracking
                setTimeout(() => {
                    setLanguageSaved(false);
                }, 3000);
            } catch (error) {
                console.error("Error saving language:", error);
            }
    
            setTimeout(() => {
                setIsSaving(false);
                localStorage.removeItem(debounceKey);
            }, 5000);
        };
    
        return (
            <div className="general-content">
                <div className="profile-section">
                    <div className="profile-image-container">
                        <img src={profileImage} alt="Profile" className="profile-image" />
                        <button className="update-button-uploading" onClick={() => fileInputRef.current.click()}>
                            <img src={updateButtonImage} alt="Update" className="button-image" />
                        </button>
                        <input
                            type="file"
                            ref={fileInputRef}
                            onChange={handleImageUpload}
                            style={{ display: 'none' }}
                        />
                    </div>
                </div>
    
                <div className="user-details">
                    <div className="email-section-wrapper">
                        <div className="email-section">
                            <img src={bumpupsLogo} alt="Bumpups Logo" className="email-bumpups-logo" />
                            <div className="email-text-wrapper">
                                <div className="email-text">{email}</div>
                            </div>
                        </div>
                    </div>
    
                    <div className="plan-container">
                        <div className="main-plan-text">
                            <img src={planIcon} alt={currentPlan === "bump" ? "Bump Plan Icon" : (currentPlan === "Essential Plan" || currentPlan === "base") ? "Base Plan Icon" : `${currentPlan} Icon`} className="plan-icon" />
                            {currentPlan === "bump" ? "Bump Plan" : (currentPlan === "Essential Plan" || currentPlan === "base") ? "Base Plan" : currentPlan}
                        </div>
                        <Link to="/plans" style={{ textDecoration: 'none', color: 'inherit' }}>
                            <button className="plan-button">Manage</button>
                        </Link>
                    </div>
    
                    <div className="plan-container">
                    <div className="default-lang-wrapper">
                        <div className="default-lang-container">
                            <span className="selected-language-text">{selectedLanguage}</span>
                        </div>

                        <div className="lang-dropdown-save-wrapper">
                            <select className="manage-lang-dropdown" value={language} onChange={handleLanguageChange}>
                                {languages.map(lang => (
                                    <option key={lang.value} value={lang.value}>
                                        {lang.label}
                                    </option>
                                ))}
                            </select>
                            <button
                                className="save-lang-button"
                                onClick={saveLanguageSetting}
                                disabled={isSaving || !isLanguageChanged} // Disable if no changes or saving in progress
                            >
                                {languageSaved ? 'Saved' : 'Save'}
                            </button>

                            <div className="mainsettings-tooltip-container">
                                <img
                                    src={infoLogo}
                                    alt="Info"
                                    className="mainsettings-info-logo"
                                    onClick={() => window.open('https://intercom.help/bumpups/en/articles/8854829-which-languages-are-supported', '_blank')}
                                />
                            </div>

                        </div>
                    </div>
                </div>  


                </div>
            </div>
        );
    };
    

    const CreatorSettings = () => {
        return (
            <div className="creator-content">
                <div className="settings-action">
                    <div className="icon-text-container">
                        <img src={utubeIcon} alt="YouTube" className="action-icon" />
                        <span className="action-text">YouTube</span>
                    </div>
                    <button
                        className={`settings-utube-connect-button ${isConnected ? 'connected' : ''}`}
                        onClick={() => isConnected ? null : handleConnectYouTube()}
                        style={{ cursor: isConnected ? 'default' : 'pointer' }}
                    >
                        <img
                            src={isConnected ? utubeLogoConnected : utubeDisconnect}
                            alt="YouTube Button"
                            className="settings-create-connectyoutube"
                        />
                    </button>
                    {isConnected && (
                        <button className="revoke-button" onClick={handleRevokeClick}>
                            {revokeButtonText}
                        </button>
                    )}
                </div>
                <div className={`settings-action ${!isToggledcomment ? 'disabled-section' : ''}`}>
                    <div className="icon-text-container">
                        <img src={commentIcon} alt="Comment" className="action-icon" />
                        <span className="action-text">Comment Timestamps</span>
                    </div>
                    <button className="manage-button" disabled={!isToggledcomment} onClick={manageComments}>
                        {isToggledcomment ? (
                            showBrandTemplate ? <img src={revokeLogo} alt="Close" /> : <img src={brandingLogo} alt="Manage" />
                        ) : <img src={brandingLogo} alt="Manage" />}
                    </button>
                    <label className={`switch`}>
                        <input type="checkbox" className="toggle-input" checked={isToggledcomment} onChange={handleCommentToggleChange} />
                        <span className="slider round"></span>
                        <span className="slider-tooltip-text">
                            <img src={commentIcon} alt="Tooltip Icon" className="tooltip-icon" /> Auto-Comment Timestamps
                        </span>
                    </label>
                </div>
                {isToggledcomment && showBrandTemplate && <BrandTemplate />}
                <div className={`settings-action ${!isToggledsubtitles ? 'disabled-section' : ''}`}>
                    <div className="icon-text-container">
                        <img src={subtitleIcon} alt="Subtitles" className="action-icon" />
                        <span className="action-text">Subtitles [Soon]</span>
                    </div>
                    <button className="manage-button" disabled={!isToggledsubtitles} onClick={manageSubtitles}>
                        {isToggledcomment ? (
                            showSubtitleTemplate ? <img src={revokeLogo} alt="Close" /> : <img src={generalIcon} alt="Manage" />
                        ) : <img src={generalIcon} alt="Manage" />}
                    </button>
                </div>
                {isToggledsubtitles && showSubtitleTemplate && <SubtitleTemplate />}
            </div>
        );
    };

    const debouncedTimestampHandleToggle = debounce(async (isShort, setIsShortTimestamps) => {
        setIsShortTimestamps(isShort);

        const user = auth.currentUser;
        if (user) {
            const timestampSettingsRef = doc(db, 'users', user.uid, 'valueSettings', 'timestamps');

            try {
                await updateDoc(timestampSettingsRef, {
                    isShortTimestamps: isShort
                });
                const userData = JSON.parse(sessionStorage.getItem(`userData_${user.uid}`)) || {};
                userData.isShortTimestamps = isShort;
                sessionStorage.setItem(`userData_${user.uid}`, JSON.stringify(userData));
            } catch (error) {
                // Handle error
            }
        }
    }, 500);

    const handleLengthOption = (value) => {
        const isShort = value === 'short';
        debouncedTimestampHandleToggle(isShort, setIsShortTimestamps);
    };

    const TimestampSettings = () => {
        return (
            <div>
                <div className="settings-action">
                    <div className="icon-text-container">
                        <img src={stamplengthIcon} alt="Timestamp Length" className="action-icon" />
                        <div className="action-text-wrapper">
                            <span className="action-text">
                                Timestamp Length
                            </span>
                            <a href="https://intercom.help/bumpups/en/articles/9108586-what-timestamp-length-is-right-for-your-content" target="_blank" rel="noopener noreferrer" className="info-link">
                                <img src={infoLogo} alt="Info" className="info-icon" />
                            </a>
                        </div>
                    </div>
                    <div className="timestamp-length-buttons">
                        <button
                            value="short"
                            className={`timestamp-button short ${isShortTimestamps ? 'selected' : ''}`}
                            onClick={() => handleLengthOption('short')}
                        >
                            Short
                        </button>
                        <button
                            value="standard"
                            className={`timestamp-button standard ${!isShortTimestamps ? 'selected' : ''}`}
                            onClick={() => handleLengthOption('standard')}
                        >
                            Standard
                        </button>
                    </div>
                </div>
                <div className="settings-action">
                    <div className="icon-text-container">
                        <img src={emojiIcon} alt="Subtitles" className="action-icon" />
                        <span className="action-text">Add Emoji <i>[Coming Soon]</i></span>
                    </div>
                </div>
            </div>
        );
    };

    const uselocation = useLocation();
    const currentPath = uselocation.pathname;

    return (
        <div className="meta-settings">
            {isSidebarOpen && <Sidebar />}
            {!isSidebarOpen && <CloseSidebar />}
            <AppTransition>
                <div className="settings-container">
                    <Helmet>
                        <title>Bumpups</title>
                        <meta name="description" content="Customize your Bumpups settings." />
                    </Helmet>
                    <div className="settings-sidebar">
                        <div className={`sidebar-item ${currentPath === '/settings' ? 'active' : ''}`} onClick={() => handleTabClick('General')}>
                            <img src={generalIcon} alt="General" className="sidebar-icon" />
                            General
                        </div>
                        <div className={`sidebar-item ${currentPath === '/settings/bumping' ? 'active' : ''}`} onClick={() => handleTabClick('Bumping')}>
                            <img src={bumpUPlogo} alt="Bumping" className="sidebar-icon" />
                            Bump
                        </div>
                        <div className={`sidebar-item ${currentPath === '/settings/creator' ? 'active' : ''}`} onClick={() => handleTabClick('Creator')}>
                            <img src={creatorIcon} alt="Creator" className="sidebar-icon" />
                            Creator
                        </div>
                        <div className={`sidebar-item ${currentPath === '/settings/timestamps' ? 'active' : ''}`} onClick={() => handleTabClick('Timestamps')}>
                            <img src={timestampIcon} alt="Timestamps" className="sidebar-icon" />
                            Timestamps
                        </div>
                    </div>
                    <div className="settings-content">
                        <Routes>
                            <Route path="/" element={<GeneralSettings />} />
                            <Route path="creator" element={<CreatorSettings />} />
                            <Route path="timestamps" element={<TimestampSettings />} />
                            <Route path="bumping" element={<BumpSettings />} />
                        </Routes>
                    </div>
                </div>
            </AppTransition>
        </div>
    );
};

export default UserSettings;
