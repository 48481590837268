import { getFunctions, httpsCallable } from 'firebase/functions';



export const getYoutubeCaptionsPython = (videoId, durationInSeconds, thumbnailUrl, title, isReprocess) => {
  const functions = getFunctions();
  const getCaptionsPythonCallable = httpsCallable(functions, 'getCaptionsPython');
  const timestamp = new Date().getTime();
  const videoArray = [{
    'videoId': videoId,
    'durationInSeconds': durationInSeconds,
    'timestamp': timestamp,
    'videoType': "youtube",
    'videoThumbnail': thumbnailUrl,
    'videoTitle': title
  }];
  let is503Error = false;
  let isunAuthError = false;

  const requestData = {
    'videoArray': videoArray,
    'isReprocess': isReprocess
  };

  // console.log('Calling getCaptionsPython with data:', requestData);

  return new Promise((resolve, reject) => {
    getCaptionsPythonCallable(requestData)
      .then((result) => {
        // console.log('getCaptionsPython result:', result);
        // Process the successful result here
        resolve({ is503Error, isunAuthError });
      })
      .catch((error) => {
        const code = error.code; // Error code
        // console.error('Error calling getCaptionsPython:', error);

        if (code === 'functions/permission-denied') {
          isunAuthError = true;
        }
        if (code === 'functions/unavailable') {
          is503Error = true;
        }

        // console.error(`Error occurred - Code: ${code}, Message: ${error.message}`, error.details);

        resolve({ is503Error, isunAuthError });
      });
  });
};



export const getYoutubeURLCaptionsPython = (videoId, durationInSeconds, thumbnailUrl, title, isReprocess, fullUrl) => {
  const functions = getFunctions();
  const getCaptionsPythonCallable = httpsCallable(functions, 'getCaptionsPython');
  const timestamp = new Date().getTime();
  const videoArray = [{
    'videoId': videoId,
    'durationInSeconds': durationInSeconds,
    'timestamp': timestamp,
    'videoType': "youtubeUrl",
    'videoThumbnail': thumbnailUrl,
    'videoTitle': title,
    'fullUrl': fullUrl 
  }];
  let is503Error = false;
  let isunAuthError = false;
  let isinternalError = false;

  const requestData = {
    'videoArray': videoArray,
    'isReprocess': isReprocess
  };

  // console.log('Calling getCaptionsPython with the following data:', requestData);


  // console.log('Calling getCaptionsPython with data:', requestData);

  return new Promise((resolve, reject) => {
    getCaptionsPythonCallable(requestData)
      .then((result) => {
        // console.log('getCaptionsPython result:', result);
        resolve({ is503Error, isunAuthError, isinternalError });
      })
      .catch((error) => {
        const code = error.code;
        // console.error('Error calling getCaptionsPython:', error);

        if (code === 'functions/permission-denied') {
          isunAuthError = true;
        }
        if (code === 'functions/unavailable') {
          is503Error = true;
        }
        if (code === 'functions/internal') {
          isinternalError = true;
        }

        // console.error(`Error occurred - Code: ${code}, Message: ${error.message}`, error.details);
        resolve({ is503Error, isunAuthError, isinternalError });
      });
  });
};





// functions/permission-denied