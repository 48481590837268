import React, { useState } from 'react';
import './FAQComponent.css';

const FAQComponent = () => {
  const [faqOpen, setFaqOpen] = useState([false, false, false, false, false, false]);

  const toggleFaq = (index) => {
    setFaqOpen(faqOpen.map((isOpen, i) => (i === index ? !isOpen : isOpen)));
  };



//   const [faqOpen, setFaqOpen] = useState([false, false, false, false, false, false]);

//   const toggleFaq = (index) => {
//     const newFaqOpen = [...faqOpen];
//     newFaqOpen[index] = !newFaqOpen[index];  // Toggle the specific FAQ item
//     setFaqOpen(newFaqOpen);
//   };
  

  return (
    <div className="faq-section-pricing">
      <h1>Frequently Asked Questions</h1>

      <div className="faq-item" onClick={() => toggleFaq(0)}>
        <h3>
          What can Bumpups do for my content?
          <span className={faqOpen[0] ? "caret rotate" : "caret"}>⌄</span>
        </h3>
        <div className={faqOpen[0] ? "faq-content show" : "faq-content"}>
          Bumpups streamlines your video creation and publishing process by automatically generating optimized metadata such as timestamps, descriptions, titles, and tags. <br /><br />
          This not only saves you time but also enhances the visibility and engagement of your content on platforms like YouTube. <br /><br />
          With Bumpups, you can focus on creating great content while our AI handles the technical details to help your videos reach a wider audience.
        </div>
      </div>

      <div className="faq-item" onClick={() => toggleFaq(1)}>
        <h3>
          Is Bumpups free to use?
          <span className={faqOpen[1] ? "caret rotate" : "caret"}>⌄</span>
        </h3>
        <div className={faqOpen[1] ? "faq-content show" : "faq-content"}>
          Yes, Bumpups offers 60 minutes of free processing, and no credit card is required to start. <br /><br />
          Simply create an account to begin using all features. To continue beyond the free plan, you can upgrade for more video processing hours each month.
        </div>
      </div>

      <div className="faq-item" onClick={() => toggleFaq(2)}>
        <h3>
          How does Bump AI process my video?
          <span className={faqOpen[2] ? "caret rotate" : "caret"}>⌄</span>
        </h3>
        <div className={faqOpen[2] ? "faq-content show" : "faq-content"}>
          After you upload your video, Bump AI immediately starts processing it to generate optimized content. It creates engaging descriptions, SEO-friendly titles, key takeaways, timestamps, and relevant tags.<br /><br />
          You'll see a time estimate for how long this will take, keeping you informed throughout the process.
        </div>
      </div>

      <div className="faq-item" onClick={() => toggleFaq(3)}>
        <h3>
          What video durations can the platform support?
          <span className={faqOpen[3] ? "caret rotate" : "caret"}>⌄</span>
        </h3>
        <div className={faqOpen[3] ? "faq-content show" : "faq-content"}>
          Our platform supports videos ranging from <b>30 seconds to 3.5 hours</b> in length. We are actively working on extending this range to accommodate longer videos.
        </div>
      </div>

      <div className="faq-item" onClick={() => toggleFaq(4)}>
        <h3>
          How do I publish my video to YouTube using Bumpups?
          <span className={faqOpen[4] ? "caret rotate" : "caret"}>⌄</span>
        </h3>
        <div className={faqOpen[4] ? "faq-content show" : "faq-content"}>
          Publishing your video with Bumpups is simple and efficient. Upload your video, let our Bump AI handle the metadata like timestamps and descriptions, review and edit the content, then publish directly to YouTube.
        </div>
      </div>

      <div className="faq-item" onClick={() => toggleFaq(5)}>
        <h3>
          Can I upload local files?
          <span className={faqOpen[5] ? "caret rotate" : "caret"}>⌄</span>
        </h3>
        <div className={faqOpen[5] ? "faq-content show" : "faq-content"}>
          <b>Yes</b>, with the Bump Plan, the processing of local video files is now supported.
        </div>
      </div>
    </div>
  );
};

export default FAQComponent;
