import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import './PricingPage.css';
import { AuthProvider } from '../AuthContext';
import Footer from './Footer';
import FAQComponent from './FAQComponent';
import checkmarkIcon from '../assets/images/checkmark-v1.png';
import basePlanLogo from '../assets/images/baseplan-white.png';
import bumpPlanLogo from '../assets/images/bumpups-white.png';
import PricingTable from './PricingTable';

const PricingPage = () => {
  const [selectedCreditsBump, setSelectedCreditsBump] = useState(100);
  const [selectedCreditsBase, setSelectedCreditsBase] = useState(100);

  const navigate = useNavigate();
  
  const handleSignUp = () => {
    navigate('/signup');
  };


  const handleCreditsChangeBump = (e) => {
    setSelectedCreditsBump(parseInt(e.target.value, 10));
  };

  const handleCreditsChangeBase = (e) => {
    setSelectedCreditsBase(parseInt(e.target.value, 10));
  };

  const calculatePrice = (plan, credits) => {
    let multiplier = plan === 'Bump' ? 9.99 : 1.99;
    return (credits / 100 * multiplier).toFixed(2);
  };

  return (
    <AuthProvider>
      <Helmet>
        <title>Bumpups - Pricing</title>
        <meta name="description" content="Our AI-powered platform enhances your video content." />
      </Helmet>
      <div className="bp-pricing-page">
        <header className="bp-pricing-header">
          <h1>Plans & Pricing</h1>
          <div className="bp-billing-toggle">
            <span className="bp-active">Monthly</span>
            <span className="bp-coming-soon">Yearly <em>(coming soon)</em></span>
          </div>
        </header>

        <section className="bp-pricing-tiers">
          <div className="bp-tier bp-bump">
          <div className="bp-plan-title">
              <img src={bumpPlanLogo} alt="Bump Plan Logo" className="bp-plan-logo" />
              <div className="bp-plan-title-card">Bump</div>
            </div>
            <p className="bp-billing-info">For complete content management</p>
            <p className="bp-price">${calculatePrice('Bump', selectedCreditsBump)} USD</p>
            <p className="bp-upload-text bump-plan-text"><strong>{selectedCreditsBump} upload minutes</strong></p>
            <select 
              className="bp-minutes-select" 
              onChange={handleCreditsChangeBump} 
              value={selectedCreditsBump}
            >
              <option value={100}>100 credits</option>
              <option value={200}>200 credits</option>
              <option value={400}>400 credits</option>
              <option value={600}>600 credits</option>
              <option value={800}>800 credits</option>
              <option value={1000}>1000 credits</option>
            </select>
        <button className="bp-cta-button" onClick={handleSignUp}>Start your free plan</button>
            <ul className="bp-features-list">
            <li><img src={checkmarkIcon} alt="Checkmark" className="bp-checkmark-icon" /> <strong>{selectedCreditsBump}</strong>&nbsp;processing minutes per month</li>
            <li><img src={checkmarkIcon} alt="Checkmark" className="bp-checkmark-icon" /> <strong>One-click</strong>&nbsp;distribution</li>
            <li><img src={checkmarkIcon} alt="Checkmark" className="bp-checkmark-icon" /> <strong>Local video</strong>&nbsp;processing</li>
            <li><img src={checkmarkIcon} alt="Checkmark" className="bp-checkmark-icon" /> Social media posting</li>
            <li><img src={checkmarkIcon} alt="Checkmark" className="bp-checkmark-icon" /> Multiple aspect ratios</li>
            </ul>
          </div>

          <div className="bp-tier bp-base">
          <div className="bp-plan-title">
              <img src={basePlanLogo} alt="Base Plan Logo" className="bp-plan-logo" />
              <div className="bp-plan-title-card">Base</div>
            </div>
            <p className="bp-billing-info">For essential content optimization</p>
            <p className="bp-price">${calculatePrice('Base', selectedCreditsBase)} USD</p>
            <p className="bp-upload-text base-plan-text"><strong>{selectedCreditsBase} upload minutes</strong></p>
            <select 
              className="bp-minutes-select" 
              onChange={handleCreditsChangeBase} 
              value={selectedCreditsBase}
            >
              <option value={100}>100 credits</option>
              <option value={200}>200 credits</option>
              <option value={400}>400 credits</option>
              <option value={600}>600 credits</option>
              <option value={800}>800 credits</option>
              <option value={1000}>1000 credits</option>
            </select>
            <button className="bp-cta-button" onClick={handleSignUp}>Start your free plan</button>
            <ul className="bp-features-list">
            <li><img src={checkmarkIcon} alt="Checkmark" className="bp-checkmark-icon" /> <strong>{selectedCreditsBase}</strong>&nbsp;processing minutes per month</li>
            <li><img src={checkmarkIcon} alt="Checkmark" className="bp-checkmark-icon" /> Custom brand template</li>
              <li><img src={checkmarkIcon} alt="Checkmark" className="bp-checkmark-icon" /> Branded comments</li>
            </ul>
          </div>

          <div className="bp-tier bp-free">
            <div className="bp-plan-title-card">Free</div>
            <p className="bp-billing-info">Start your creative journey with no commitment</p>
            <p className="bp-price">$0 USD</p>
            <button className="bp-cta-button" onClick={handleSignUp}>Create an account</button>
            <ul className="bp-features-list">
              <li><img src={checkmarkIcon} alt="Checkmark" className="bp-checkmark-icon" /> <strong>60</strong>&nbsp;processing minutes</li>
              <li><img src={checkmarkIcon} alt="Checkmark" className="bp-checkmark-icon" /> <strong>Bump AI</strong>&nbsp;video optimization</li>
              <li><img src={checkmarkIcon} alt="Checkmark" className="bp-checkmark-icon" /> YouTube video processing</li>
              <li><img src={checkmarkIcon} alt="Checkmark" className="bp-checkmark-icon" /> Video timestamps</li>
            </ul>
          </div>
        </section>
        <div className="bp-enterprise-tier">
            <div className="bp-enterprise-content">
                <span className="bp-enterprise-badge">Enterprise</span>
                <span className="bp-enterprise-text">
                    For organizations that need over 5 seats, large volume usage over 1,000 hr/mo, and API. 
                    <a href="https://bumpups.zapier.app/get-in-touch" className="bp-enterprise-contact" target="_blank" rel="noopener noreferrer">Contact us</a>
                </span>
            </div>
        </div>
          <PricingTable />

          <FAQComponent />

      </div>
      <Footer />
    </AuthProvider>
  );
};

export default PricingPage;
