import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.css';

//FIREBASE
import { db, auth } from '../firebase';
import { doc, onSnapshot, getDoc } from 'firebase/firestore';

//FUNCTIONS
import { AuthContext } from '../AuthContext';

//IMPORT UI
import CloseSidebar from './CloseSidebar';

//IMAGES
import bumpUPlogo from '../assets/images/bumparrow.png';
import helpIcon from '../assets/images/v2-helpicon.png';
import dashboardLogo from '../assets/images/dashboard-logo-v6.png';
import creatoricon from '../assets/images/v2-creator.png';
import subicon from '../assets/images/v2-plan.png';
import linkIcon from '../assets/images/link-v3.png';


function Sidebar() {
  const [currentView, setCurrentView] = useState(window.location.pathname);
  const [isNarrowScreen, setIsNarrowScreen] = useState(window.innerWidth < 1280);


  const initialState = localStorage.getItem(`currentPlan_${auth.currentUser?.uid}`) || 'Free Plan';
  const [currentPlan, setCurrentPlan] = useState(initialState);

  const initialLanguage = localStorage.getItem(`userLanguage_${auth.currentUser?.uid}`) || '🇺🇸 English';
  const [language, setLanguage] = useState(initialLanguage);

  


  const { currentUser } = useContext(AuthContext);

  useEffect(() => {

    const updateView = () => {
      setCurrentView(window.location.pathname);
    };
  
    window.addEventListener('popstate', updateView);
    updateView();
  
    const handleResize = () => {
      setIsNarrowScreen(window.innerWidth < 1280);
    };
  
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('popstate', updateView);
      window.removeEventListener('resize', handleResize);
    };
  }, [currentUser]);



  useEffect(() => {

    const languages = [
      { value: 'en', label: 'en', name: '🇺🇸 English' }, 
      { value: 'hi', label: 'hi', name: '🇮🇳 Hindi' }, 
      { value: 'es', label: 'es', name: '🇪🇸 Spanish' }, 
      { value: 'pt', label: 'pt', name: '🇵🇹 Portuguese' }, 
      { value: 'ru', label: 'ru', name: '🇷🇺 Russian' }, 
      { value: 'de', label: 'de', name: '🇩🇪 German' }, 
      { value: 'fr', label: 'fr', name: '🇫🇷 French' }, 
      { value: 'ja', label: 'ja', name: '🇯🇵 Japanese' }, 
      { value: 'ko', label: 'ko', name: '🇰🇷 Korean' }, 
      { value: 'ar', label: 'ar', name: '🇸🇦 Arabic' }
    ];

    const user = auth.currentUser;
    if (user) {
        const userDocRef = doc(db, 'users', user.uid, 'userSettings', 'profile');
        const planDetailsRef = doc(db, 'users', user.uid, 'subscriptionData', 'planDetails');

        // Fetch from Firestore both plan and user language
        const fetchUserDetails = async () => {
            try {
                // Fetching plan details
                const planDoc = await getDoc(planDetailsRef);
                if (planDoc.exists()) {
                    const currentPlan = planDoc.data().currentPlan;
                    setCurrentPlan(currentPlan);
                    localStorage.setItem(`currentPlan_${user.uid}`, currentPlan);
                }

                // Fetching user settings for language preference
                const localLanguage = localStorage.getItem(`userLanguage_${user.uid}`);
                if (localLanguage) {
                    setLanguage(localLanguage);
                } else {
                    const userDoc = await getDoc(userDocRef);
                    if (userDoc.exists()) {
                        const userData = userDoc.data();
                        const userLanguage = userData.userDefaultLanguage;
                        const selectedLanguage = languages.find(lang => lang.value === userLanguage);

                        if (selectedLanguage) {
                            setLanguage(selectedLanguage.name);
                            localStorage.setItem(`userLanguage_${user.uid}`, selectedLanguage.name);
                        } else {
                            // Only default to English if there's no valid language in Firestore
                            setLanguage('🇺🇸 English');
                            localStorage.setItem(`userLanguage_${user.uid}`, '🇺🇸 English');
                        }
                    } else {
                        // If no userDoc exists, default to English
                        setLanguage('🇺🇸 English');
                        localStorage.setItem(`userLanguage_${user.uid}`, '🇺🇸 English');
                    }
                }
            } catch (error) {
                console.error("Error fetching user details: ", error);
                // Default to English in case of any error
                setLanguage('🇺🇸 English');
            }
        };

        fetchUserDetails();

        // Real-time listener for plan updates
        const unsubscribePlan = onSnapshot(planDetailsRef, (docSnap) => {
            if (docSnap.exists()) {
                const newCurrentPlan = docSnap.data().currentPlan;
                const storedCurrentPlan = localStorage.getItem(`currentPlan_${user.uid}`);

                if (!storedCurrentPlan || storedCurrentPlan !== newCurrentPlan) {
                    setCurrentPlan(newCurrentPlan);
                    localStorage.setItem(`currentPlan_${user.uid}`, newCurrentPlan);
                }
            }
        });

        // Real-time listener for language updates
        const unsubscribeLanguage = onSnapshot(userDocRef, (docSnap) => {
            if (docSnap.exists()) {
                const newLanguage = docSnap.data().userDefaultLanguage;
                const storedLanguage = localStorage.getItem(`userLanguage_${user.uid}`);
                const selectedLanguage = languages.find(lang => lang.value === newLanguage);

                if (selectedLanguage) {
                    // Update state and localStorage only if the language has changed
                    if (!storedLanguage || storedLanguage !== selectedLanguage.name) {
                        setLanguage(selectedLanguage.name);
                        localStorage.setItem(`userLanguage_${user.uid}`, selectedLanguage.name);
                    }
                } else {
                    // Only default to English if the Firestore value is invalid
                    if (!storedLanguage) {
                        setLanguage('🇺🇸 English');
                        localStorage.setItem(`userLanguage_${user.uid}`, '🇺🇸 English');
                    }
                }
            }
        });

        return () => {
            unsubscribePlan();
            unsubscribeLanguage();
        };
    }
}, [currentUser]);



        
  // If the screen is narrow, render the CloseSidebar component instead
  if (isNarrowScreen) {
    return <CloseSidebar />;
  }


  const isCurrentView = (view) => currentView === view;

  const getLinkClass = (view) => {
    return `sidebar-link ${isCurrentView(view) ? 'active' : ''}`;
  };

  const getIconClass = (view) => {
    return `link-icon ${isCurrentView(view) ? 'active-icon' : ''}`;
};

  // const timeUsed = 10; // Example value
  // const timeUsedPercentage = totalTime !== "Loading..." ? (timeUsed / totalTime) * 100 : 0;


  return (
    <div className="sidebar">
      <Link to="/bumper" className="bumpupload-button">
        <div className="bumpupload-content">
          <img src={bumpUPlogo} alt="Bump Up Logo" className="link-icon-bump" />
          <div className="bumpbutton-text">BUMP</div>
        </div>
      </Link>
        <div className="dividersides"></div>
        <Link to="/dashboard" className={getLinkClass('/dashboard')} style={{ textDecoration: 'none', color: 'inherit' }}>
            <img src={dashboardLogo} alt="Dashboard" className={getIconClass('/dashboard')} />
            Dashboard
        </Link>
        <div className="dividersides"></div>
        <Link to="/creator" className={getLinkClass('/creator')} style={{ textDecoration: 'none', color: 'inherit' }}>
            <img src={creatoricon} alt="Creator" className={getIconClass('/creator')} />
            Creator
        </Link>
        <Link to="/links" className={getLinkClass('/links')} style={{ textDecoration: 'none', color: 'inherit' }}>
            <img src={linkIcon} alt="Links" className={getIconClass('/links')} />
            Links
        </Link>
        <a href="https://intercom.help/bumpups/en/" target="_blank" rel="noopener noreferrer" className={getLinkClass('/help')}>
            <img src={helpIcon} alt="Help" className={getIconClass('/help')} />
            Help
        </a>
        <div className="plan-section">
            <div className="sidebar-footer-details">
                <div className="section-title">
                    <img src={subicon} alt={currentPlan} className="section-icon" />
                    {currentPlan === "bump" ? "Bump Plan" : (currentPlan === "Essential Plan" || currentPlan === "base") ? "Base Plan" : currentPlan}
                </div>

                <div className="languagedefault-title" onClick={() => window.location.href = '/settings'}>
                    <span>{language}</span>
                </div>


                
            </div>

            <div className="plan-status">
                <Link to="/plans" className="manage-plan">
                    Manage plan
                </Link>
            </div>
        </div>

    </div>
);


}

export default Sidebar;
