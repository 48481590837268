import React, { useState, useEffect, useCallback } from 'react';
import './editMyBump.css';
import { getDownloadURL, ref, getMetadata } from 'firebase/storage';
import { doc, onSnapshot} from 'firebase/firestore';
import { auth, videoStorage, db } from '../../firebase';
import utubeLogo from '../../assets/images/v2-creator.png';
import noAccessImageActive from '../../assets/images/loadingUI.jpg';
import { postYoutube2GB, postYoutube4GB, postYoutube16GB, postYoutube32GB } from '../postYoutube';
import { CLIENT_ID, SCOPES } from '../../googleOAuth';


import utubeLogoConnected from '../../assets/images/redYouTube-v1.png';
import bumpupsWhite from '../../assets/images/bumpups-white.png';

const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
];

const days = Array.from({ length: 31 }, (_, i) => String(i + 1));
const years = Array.from({ length: 5 }, (_, i) => String(new Date().getFullYear() + i));
const hours = Array.from({ length: 12 }, (_, i) => String(i + 1));
const minutes = Array.from({ length: 60 }, (_, i) => String(i).padStart(2, '0'));
const amPmOptions = ['AM', 'PM'];

const UserBumpDetailsSection = ({ videoId, user, accessToken, videoTitle, videoQuality, is_youtube_posted, videoDuration, videoUploadTime, isTranscriptReady, isProcessedbyAI, videoSize }) => {

    const visibilityKey = `${videoId}_visibility`;
    const defaultVisibility = localStorage.getItem(visibilityKey) || 'public';

    const [isUploadPopupOpen, setIsUploadPopupOpen] = useState(false);
    const [isSchedulePopupOpen, setIsSchedulePopupOpen] = useState(false);
    const [visibility, setVisibility] = useState(defaultVisibility);
    const [lastNonScheduleVisibility, setLastNonScheduleVisibility] = useState(defaultVisibility === 'schedule' ? 'public' : defaultVisibility);
    const [scheduleOption, setScheduleOption] = useState(defaultVisibility === 'schedule');
    const [videoUrl, setVideoUrl] = useState('');
    const [isDateInvalid, setIsDateInvalid] = useState(false);
    const [isDateHourAhead, setIsDateHourAhead] = useState(true);


    
    const scheduleDateKey = `${videoId}_scheduleVideo`;
    const savedScheduleDate = JSON.parse(localStorage.getItem(scheduleDateKey));

    const nextDay = new Date();
    nextDay.setDate(nextDay.getDate() + 1);
    const defaultMonth = months[nextDay.getMonth()];
    const defaultDay = String(nextDay.getDate());
    const defaultYear = String(nextDay.getFullYear());
    const defaultHour = '12';
    const defaultMinute = '00';
    const defaultAmPm = 'AM';

    const [scheduleMonth, setScheduleMonth] = useState(savedScheduleDate?.month || defaultMonth);
    const [scheduleDay, setScheduleDay] = useState(savedScheduleDate?.day || defaultDay);
    const [scheduleYear, setScheduleYear] = useState(savedScheduleDate?.year || defaultYear);
    const [scheduleHour, setScheduleHour] = useState(savedScheduleDate?.hour || defaultHour);
    const [scheduleMinute, setScheduleMinute] = useState(savedScheduleDate?.minute || defaultMinute);
    const [scheduleAmPm, setScheduleAmPm] = useState(savedScheduleDate?.amPm || defaultAmPm);
    const [selectedTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);

    useEffect(() => {
        localStorage.setItem(visibilityKey, visibility);
    }, [visibility, visibilityKey]);

    const validateDate = useCallback(() => {
        const selectedDate = new Date(`${scheduleMonth} ${scheduleDay}, ${scheduleYear} ${scheduleHour}:${scheduleMinute} ${scheduleAmPm}`);
        const now = new Date();
        const oneHourFromNow = new Date(now.getTime() + 60 * 60 * 1000); // 1 hour ahead
    
        if (selectedDate < now) {
            setIsDateInvalid(true);
        } else {
            setIsDateInvalid(false);
        }
    
        if (selectedDate < oneHourFromNow) {
            setIsDateHourAhead(false);
        } else {
            setIsDateHourAhead(true);
        }
    }, [scheduleMonth, scheduleDay, scheduleYear, scheduleHour, scheduleMinute, scheduleAmPm]);
    
    useEffect(() => {
        validateDate();
        const scheduleDate = {
            month: scheduleMonth,
            day: scheduleDay,
            year: scheduleYear,
            hour: scheduleHour,
            minute: scheduleMinute,
            amPm: scheduleAmPm,
        };
        localStorage.setItem(scheduleDateKey, JSON.stringify(scheduleDate));
    }, [scheduleMonth, scheduleDay, scheduleYear, scheduleHour, scheduleMinute, scheduleAmPm, scheduleDateKey, validateDate]);
    



    useEffect(() => {
        if (!user) return;
        const fetchVideoUrl = async () => {
            const videoRef = ref(videoStorage, `${user.uid}/${videoId}`);
            try {
                const url = await getDownloadURL(videoRef);
                setVideoUrl(url);
            } catch (error) {
                // console.error("Error fetching video URL: ", error);
            }
        };
        fetchVideoUrl();
    }, [user, videoId]);




    const handleVisibilityChange = (option) => {
        if (option !== 'schedule') {
            setLastNonScheduleVisibility(option);
        }
        localStorage.removeItem(visibilityKey);
        setVisibility(option);
        setScheduleOption(option === 'schedule');
    };

    const handleOverlayClick = (event, closePopup) => {
        if (event.target === event.currentTarget) {
            closePopup();
        }
    };

    const [sentYouTubeData, setSentYouTubeData] = useState(false);

    const handlePostYoutube = async () => {
        // console.log('Clicked Youtube');
    
        const debouncePostingKey = `${videoId}_${user.uid}_YouTube_debounce`;
        const lastPostTimestamp = localStorage.getItem(debouncePostingKey);
        const currentTime = Date.now();
    
        if (lastPostTimestamp && (currentTime - lastPostTimestamp) < 10000) { // 10 second debounce
            // console.log('Debounce active, please wait before posting again');
            return;
        }
    
        setSentYouTubeData(true);
        localStorage.setItem(debouncePostingKey, currentTime.toString());
    
        // Get video size from Firebase Storage
        const videoRef = ref(videoStorage, `${user.uid}/${videoId}`);
        let videoSize;
        
        try {
            const metadata = await getMetadata(videoRef);
            videoSize = metadata.size; // size in bytes
            // console.log(`Video size: ${videoSize} bytes`);
        } catch (error) {
            // console.error("Error fetching video metadata: ", error);
            setSentYouTubeData(false);
            return;
        }
    
        // Determine which function to use based on video size
        let postFunction;
        if (videoSize <= 1 * 1024 * 1024 * 1024) { // <= 1GB
            postFunction = postYoutube2GB;
        } else if (videoSize <= 3 * 1024 * 1024 * 1024) { // <= 3GB
            postFunction = postYoutube4GB;
        } else if (videoSize <= 15 * 1024 * 1024 * 1024) { // <= 15GB
            postFunction = postYoutube16GB;
        } else if (videoSize <= 31 * 1024 * 1024 * 1024) { // <= 31GB
            postFunction = postYoutube32GB;
        } else {
            // console.error('Video size exceeds 31GB, unable to upload.');
            setSentYouTubeData(false);
            return;
        }
    
        if (visibility === 'schedule') {
            const scheduleDate = new Date(`${scheduleMonth} ${scheduleDay}, ${scheduleYear} ${scheduleHour}:${scheduleMinute} ${scheduleAmPm}`);
            
            // Convert to UTC
            const localDate = new Date(scheduleDate.toLocaleString('en-US', { timeZone: selectedTimezone }));
            const utcDate = new Date(localDate.toISOString());
    
            const scheduleTimestamp = utcDate.toISOString();
            await postFunction(videoId, visibility, user.uid, scheduleTimestamp);
        } else {
            await postFunction(videoId, visibility, user.uid);
        }
    
        setTimeout(() => {
            setSentYouTubeData(false);
            localStorage.removeItem(debouncePostingKey);
        }, 10000); // 10 second debounce
    };
    
    
    
    const [isYouTubeProcessing, setIsYouTubeProcessing] = useState(false);
    const [isYouTubePosted, setIsYouTubePosted] = useState(false);
    const [youtubeID, setYoutubeID] = useState(''); // New state for youtube_video_id
    
    useEffect(() => {
        if (!user) return;
    
        const videoDocRef = doc(db, 'users', user.uid, 'bumpContent', `bump-${videoId}`);
    
        // Listen for updates to isYouTubeProcessing, isYouTubePosted, and youtubeID
        const unsubscribe = onSnapshot(videoDocRef, (docSnapshot) => {
            if (docSnapshot.exists()) {
                const data = docSnapshot.data();
                setIsYouTubeProcessing(data.is_youtube_uploading || false);
                setIsYouTubePosted(data.is_youtube_posted || false);
                setYoutubeID(data.youtube_video_id || ''); // Update youtubeID state
            }
        }, (error) => {
            // console.error("Error listening to YouTube status updates: ", error);
        });
    
        // Cleanup the listener on unmount
        return () => unsubscribe();
    }, [user, videoId]);
    





const handleConnectYouTube = () => {
    const user = auth.currentUser;
    const userId = user ? user.uid : null;

    const connectYoutubeUrl = `${process.env.REACT_APP_FUNCTIONS_ENDPOINT}/connectYoutube`;

    if (userId) {
        const authUrl = `https://accounts.google.com/o/oauth2/auth?client_id=${CLIENT_ID}&redirect_uri=${encodeURIComponent(connectYoutubeUrl)}&scope=${encodeURIComponent(SCOPES)}&response_type=code&access_type=offline&state=${userId}`;
        window.location.href = authUrl;
    } else {
        // console.error("User not authenticated");
    }
};


const [bumpingTimeLeft, setBumpingTimeLeft] = useState('');

useEffect(() => {
    const calculateBumpingTimeLeft = () => {
        if (!videoUploadTime) {
            setBumpingTimeLeft('Upload time not available');
            return;
        }

        let totalProcessingTimeMinutes = localStorage.getItem(`${videoId}_processingTimeMinutes`);
        let uploadTimeInMillis = localStorage.getItem(`${videoId}_uploadTimeInMillis`);

        if (!totalProcessingTimeMinutes || !uploadTimeInMillis) {
            if (videoDuration <= 600) { // 600 seconds = 10 minutes
                const minProcessingTime = 2;
                const maxProcessingTime = 7;
                const processingFactor = (videoDuration / 600); // Scales between 0 and 1 for videos up to 10 minutes
                totalProcessingTimeMinutes = minProcessingTime + processingFactor * (maxProcessingTime - minProcessingTime);
            } else {
                const minProcessingTime = 7;
                const maxProcessingTime = 12;
                const processingFactor = Math.min((videoDuration / 3600), 1); // Scales between 0 and 1, maxes out at 1 hour
                totalProcessingTimeMinutes = minProcessingTime + processingFactor * (maxProcessingTime - minProcessingTime);
            }
        
            uploadTimeInMillis = videoUploadTime.getTime();
        
            localStorage.setItem(`${videoId}_processingTimeMinutes`, totalProcessingTimeMinutes);
            localStorage.setItem(`${videoId}_uploadTimeInMillis`, uploadTimeInMillis);
        }
        
        const currentTimeInMillis = Date.now();
        const elapsedTimeMinutes = (currentTimeInMillis - uploadTimeInMillis) / (1000 * 60);

        const remainingTimeMinutes = totalProcessingTimeMinutes - elapsedTimeMinutes;

        if (remainingTimeMinutes > 60) {
            setBumpingTimeLeft(`${Math.ceil(remainingTimeMinutes / 60)} hours left`);
        } else if (remainingTimeMinutes > 1) {
            setBumpingTimeLeft(`${Math.ceil(remainingTimeMinutes)} minutes left`);
        } else if (remainingTimeMinutes > 0) {
            setBumpingTimeLeft(`1 minute left`);
        } else {
            setBumpingTimeLeft('successfully bumped');
        }
    };

    calculateBumpingTimeLeft();
    const interval = setInterval(calculateBumpingTimeLeft, 60000);

    return () => clearInterval(interval);
}, [videoDuration, videoUploadTime, videoId]);






const [bumpUploadingTime, setBumpUploadingTime] = useState('');

useEffect(() => {
    if (!isYouTubeProcessing) return;

    const calculateBumpUploadingTime = () => {
        if (!videoSize) {
            setBumpUploadingTime('Video size not available');
            return;
        }

        // Check if the processing time is already stored in localStorage
        let totalProcessingTimeMinutes = localStorage.getItem(`${videoId}_bumpUploadingTime`);
        let uploadStartTimeInMillis = localStorage.getItem(`${videoId}_uploadStartTimeInMillis`);

        if (!totalProcessingTimeMinutes || !uploadStartTimeInMillis) {
            // Calculate the processing time using the formula: 1.5 minutes for every 5000 MB (5 GB)
            const timePerMb = 1.5 / 5100; // Time in minutes per MB
            totalProcessingTimeMinutes = Math.max(videoSize * timePerMb, 1);

            uploadStartTimeInMillis = Date.now();

            localStorage.setItem(`${videoId}_bumpUploadingTime`, totalProcessingTimeMinutes);
            localStorage.setItem(`${videoId}_uploadStartTimeInMillis`, uploadStartTimeInMillis);
        }

        const currentTimeInMillis = Date.now();
        const elapsedTimeMinutes = (currentTimeInMillis - uploadStartTimeInMillis) / (1000 * 60);

        const remainingTimeMinutes = totalProcessingTimeMinutes - elapsedTimeMinutes;

        if (remainingTimeMinutes > 60) {
            setBumpUploadingTime(`${Math.ceil(remainingTimeMinutes / 60)} hours`);
        } else if (remainingTimeMinutes > 1) {
            setBumpUploadingTime(`${Math.ceil(remainingTimeMinutes)} minutes`);
        } else if (remainingTimeMinutes > 0) {
            setBumpUploadingTime(`1 minute`);
        } else {
            setBumpUploadingTime('Finalizing uploading...');
        }
    };

    calculateBumpUploadingTime();

    // Set interval to update the processing time every 30 seconds
    const interval = setInterval(calculateBumpUploadingTime, 30000);

    return () => clearInterval(interval); // Clean up the interval on component unmount
}, [isYouTubeProcessing, videoSize, videoId]);







    return (
        <div className="editBump-section-sidebar">
            <div className="editBump-section-video">
                <div className="editBump-section-videopreview">
                    {videoUrl ? (
                        <div className="bumps-video-wrapper-preview" style={{ position: 'relative' }}>
                            <video src={videoUrl} controls className="bumps-upload-video-preview" />
                        </div>
                    ) : (
                        <img src={noAccessImageActive} alt="Video Thumbnail" className="bumps-upload-video-preview" />
                    )}
                </div>
                <div className="editBump-section-info">
                <div className="editBump-section-filename-container">
                    <div className="editBump-section-filename-label">Filename</div>
                    <div className="editBump-section-filename">{videoTitle}</div>
                    {!isTranscriptReady ? (
                        <div className="editBump-section-logo-wrapper">
                            <img src={bumpupsWhite} alt="Bumpups Logo" className="bumpups-white-logo" />
                            <div className="editBump-section-bumping-time">
                                {bumpingTimeLeft === 'successfully bumped' ? 'Finalizing process...' : `Bumping ... ${bumpingTimeLeft}`}
                            </div>
                        </div>
                    ) : null}
                </div>
                    <div className="editBump-section-quality-container">
                        <div className="editBump-section-quality">{videoQuality}</div>
                    </div>
                </div>
            </div>
            <div className="editBump-app-distribution">
                <div className="editBump-section-tags-label">Platform</div>
                <div className="editBump-thumbnail-description">Host your local videos, we bump it, and then enable seamless posting to YouTube. <a href="https://intercom.help/bumpups/en/articles/9650593-how-to-publish-your-video-with-bumpups" target="_blank" rel="noopener noreferrer">Learn more</a></div>
                
                <div className="editBump-app-distribution-item">

                    <div className="editBump-app-distribution-icon">
                        <img src={accessToken ? utubeLogoConnected : utubeLogo} alt="YouTube Logo" className="youtube-logo" />
                    </div>
                    <div className="editBump-app-distribution-content">
                        <div className="youtube-distribute-title">YouTube</div>
                        <div className="youtube-distribute-subititle">Upload to YouTube</div>
                    </div>
                    <div className="editBump-app-distribution-buttons">
                    {accessToken ? (
                            !(isTranscriptReady && isProcessedbyAI) ? (
                                <div className="editBump-section-logo-wrapper">
                                    <img src={bumpupsWhite} alt="Bumpups Logo" className="bumpups-white-logo" />
                                    <div className="editBump-section-bumping-time">
                                        Bump AI Processing...
                                    </div>
                                </div>
                            ) : isYouTubePosted ? (
                                <button
                                    className="editBump-app-distribution-btn"
                                    onClick={() => window.open(`https://www.youtube.com/watch?v=${youtubeID}`, '_blank')}
                                >
                                    View Video
                                </button>
                            ) : (
                                isYouTubeProcessing ? (
                                    <div className="editBump-section-logo-wrapper">
                                        <img src={bumpupsWhite} alt="Bumpups Logo" className="bumpups-white-logo" />
                                        <div className="editBump-section-bumping-time">
                                            {bumpUploadingTime === '1 minute' || bumpUploadingTime === 'Finalizing uploading...' ? 'Finalizing uploading...' : `Uploading ... ${bumpUploadingTime}`}
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <button
                                            className={`editBump-app-distribution-btn ${visibility !== 'schedule' ? 'isVisibilitySelected' : ''}`}
                                            onClick={() => {
                                                const currentVisibility = localStorage.getItem(visibilityKey) || 'public';
                                                if (visibility === 'schedule') {
                                                    handleVisibilityChange(lastNonScheduleVisibility || currentVisibility);
                                                } else {
                                                    handleVisibilityChange(currentVisibility);
                                                }
                                                setIsUploadPopupOpen(true);
                                            }}
                                        >
                                            Save
                                        </button>
                                        <button
                                            className={`editBump-app-distribution-btn ${visibility === 'schedule' ? 'isVisibilitySelected' : ''}`}
                                            onClick={() => {
                                                handleVisibilityChange('schedule');
                                                setIsSchedulePopupOpen(true);
                                            }}
                                        >
                                            Schedule
                                        </button>
                                    </>
                                )
                            )
                        ) : (
                            <button
                                className="editBump-app-distribution-btn"
                                onClick={handleConnectYouTube}
                            >
                                Connect
                            </button>
                        )}
                    </div>
                </div>
                
            </div>
    
            {isUploadPopupOpen && (
                <div className="editBump-popup-overlay" onClick={(e) => handleOverlayClick(e, () => setIsUploadPopupOpen(false))}>
                    <div className="editBump-popup-content">
                        <div className="editBump-popup-header">
                            <div className="editBump-popup-title">Upload</div>
                            <button className="editBump-close-btn" onClick={() => setIsUploadPopupOpen(false)}>X</button>
                        </div>
                        <div className="editBump-popup-inner-content">
                            <div className="editBump-accordion-description">Make your video <strong>public</strong>, <strong>unlisted</strong>, or <strong>private</strong></div>
                            <div className="editBump-visibility-options">
                                <div className="editBump-visibility-option" onClick={() => handleVisibilityChange('private')}>
                                    <input
                                        type="radio"
                                        id="private"
                                        name="visibility"
                                        value="private"
                                        checked={visibility === 'private' && !scheduleOption}
                                        onChange={() => handleVisibilityChange('private')}
                                        className="visibilityRadio"
                                    />
                                    <div className="editBump-visibility-text">
                                        <div className="editBump-visibility-title-wrapper">
                                            <div className="editBump-visibility-title">Private</div>
                                        </div>
                                        <div className="editBump-visibility-description">Only you and people you choose can watch your video</div>
                                    </div>
                                </div>
                                <div className="editBump-visibility-option" onClick={() => handleVisibilityChange('unlisted')}>
                                    <input
                                        type="radio"
                                        id="unlisted"
                                        name="visibility"
                                        value="unlisted"
                                        checked={visibility === 'unlisted' && !scheduleOption}
                                        onChange={() => handleVisibilityChange('unlisted')}
                                        className="visibilityRadio"
                                    />
                                    <div className="editBump-visibility-text">
                                        <div className="editBump-visibility-title-wrapper">
                                            <div className="editBump-visibility-title">Unlisted</div>
                                        </div>
                                        <div className="editBump-visibility-description">Anyone with the video link can watch your video</div>
                                    </div>
                                </div>
                                <div className="editBump-visibility-option" onClick={() => handleVisibilityChange('public')}>
                                    <input
                                        type="radio"
                                        id="public"
                                        name="visibility"
                                        value="public"
                                        checked={visibility === 'public' && !scheduleOption}
                                        onChange={() => handleVisibilityChange('public')}
                                        className="visibilityRadio"
                                    />
                                    <div className="editBump-visibility-text">
                                        <div className="editBump-visibility-title-wrapper">
                                            <div className="editBump-visibility-title">Public</div>
                                        </div>
                                        <div className="editBump-visibility-description">Everyone can watch your video</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="editBump-popup-footer">
                        <button
                            className="editBump-app-distribution-btn"
                            onClick={handlePostYoutube}
                            disabled={sentYouTubeData || isYouTubeProcessing}
                            style={{ 
                                opacity: sentYouTubeData || isYouTubeProcessing ? 0.5 : 1,
                                cursor: sentYouTubeData || isYouTubeProcessing ? 'default' : 'pointer'
                            }}
                        >
                            {sentYouTubeData || isYouTubeProcessing ? 'Uploading...' : (visibility === 'public' ? 'Publish' : visibility === 'schedule' ? 'Schedule' : 'Save')}
                        </button>
                        </div>
                    </div>
                </div>
            )}
    
            {isSchedulePopupOpen && (
                <div className="editBump-popup-overlay" onClick={(e) => handleOverlayClick(e, () => setIsSchedulePopupOpen(false))}>
                    <div className="editBump-popup-content">
                        <div className="editBump-popup-header">
                            <div className="editBump-popup-title">Schedule</div>
                            <button className="editBump-close-btn" onClick={() => setIsSchedulePopupOpen(false)}>X</button>
                        </div>
                        <div className="editBump-popup-inner-content">
                            <div className="editBump-accordion-description">Select a date to make your video <strong>public</strong>.</div>
                            <div className="editBump-visibility-option" onClick={() => handleVisibilityChange('schedule')}>
                                <input
                                    type="radio"
                                    id="schedule"
                                    name="scheduleOption"
                                    checked={scheduleOption}
                                    onChange={() => handleVisibilityChange('schedule')}
                                    className="visibilityRadio"
                                />
                                <div className="editBump-visibility-text">
                                    <div className="editBump-visibility-title-wrapper">
                                        <div className="editBump-visibility-title">From private to public</div>
                                    </div>
                                    <div className="editBump-visibility-description">Video will be <strong>private</strong> before publishing</div>
                                </div>
                            </div>
                            <div className="editBump-schedule-wrapper">
                                <div className="editBump-schedule-date-time">
                                    <div className="editBump-date-wrapper">
                                        <div className="editBump-date-label">Month</div>
                                        <select
                                            className={`editBump-schedule-dropdown ${isDateInvalid || !isDateHourAhead ? 'invalid' : ''}`}
                                            value={scheduleMonth}
                                            onChange={(e) => setScheduleMonth(e.target.value)}
                                        >
                                            {months.map((month) => (
                                                <option key={month} value={month}>{month}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="editBump-date-wrapper">
                                        <div className="editBump-date-label">Day</div>
                                        <select
                                            className={`editBump-schedule-dropdown ${isDateInvalid || !isDateHourAhead ? 'invalid' : ''}`}
                                            value={scheduleDay}
                                            onChange={(e) => setScheduleDay(e.target.value)}
                                        >
                                            {days.map((day) => (
                                                <option key={day} value={day}>{day}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="editBump-date-wrapper">
                                        <div className="editBump-date-label">Year</div>
                                        <select
                                            className={`editBump-schedule-dropdown ${isDateInvalid || !isDateHourAhead ? 'invalid' : ''}`}
                                            value={scheduleYear}
                                            onChange={(e) => setScheduleYear(e.target.value)}
                                        >
                                            {years.map((year) => (
                                                <option key={year} value={year}>{year}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="editBump-schedule-date-time">
                                    <div className="editBump-date-wrapper">
                                        <div className="editBump-date-label">Hour</div>
                                        <select
                                            className={`editBump-schedule-dropdown ${isDateInvalid || !isDateHourAhead ? 'invalid' : ''}`}
                                            value={scheduleHour}
                                            onChange={(e) => setScheduleHour(e.target.value)}
                                        >
                                            {hours.map((hour) => (
                                                <option key={hour} value={hour}>{hour}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="editBump-date-wrapper">
                                        <div className="editBump-date-label">Minute</div>
                                        <select
                                            className={`editBump-schedule-dropdown ${isDateInvalid || !isDateHourAhead ? 'invalid' : ''}`}
                                            value={scheduleMinute}
                                            onChange={(e) => setScheduleMinute(e.target.value)}
                                        >
                                            {minutes.map((minute) => (
                                                <option key={minute} value={minute}>{minute}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="editBump-date-wrapper">
                                        <div className="editBump-date-label">AM/PM</div>
                                        <select
                                            className={`editBump-schedule-dropdown ${isDateInvalid || !isDateHourAhead ? 'invalid' : ''}`}
                                            value={scheduleAmPm}
                                            onChange={(e) => setScheduleAmPm(e.target.value)}
                                        >
                                            {amPmOptions.map((option) => (
                                                <option key={option} value={option}>{option}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                {isDateInvalid && (
                                    <div className="editBump-invalid-date-error-message">
                                        You can't schedule a post to send in the past.
                                    </div>
                                )}

                                {!isDateInvalid && !isDateHourAhead && (
                                    <div className="editBump-invalid-date-error-message">
                                        Your scheduled post needs to be at least 1 hour ahead of the current time to allow for uploading.
                                    </div>
                                )}
                            </div>

    
                            <div className="editBump-timezone">
                                <div className="editBump-date-label">Time zone</div>
                                <div className="editBump-timezone-label">{selectedTimezone}</div>
                            </div>
                            <div className="editBump-schedule-summary">
                                Will send on {`${scheduleMonth} ${scheduleDay}, ${scheduleYear}`} at {`${scheduleHour}:${scheduleMinute} ${scheduleAmPm}`}
                            </div>
                        </div>
                        <div className="editBump-popup-footer">
                        <button
                            className="editBump-app-distribution-btn"
                            onClick={handlePostYoutube}
                            disabled={sentYouTubeData || isYouTubeProcessing || isDateInvalid || !isDateHourAhead}
                            style={{
                                opacity: sentYouTubeData || isYouTubeProcessing || isDateInvalid || !isDateHourAhead ? 0.5 : 1,
                                cursor: sentYouTubeData || isYouTubeProcessing || isDateInvalid || !isDateHourAhead ? 'default' : 'pointer',
                            }}
                        >
                            {sentYouTubeData || isYouTubeProcessing ? 'Uploading...' : 'Schedule'}
                        </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );


    
};

export default UserBumpDetailsSection;
