import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './PlanPopUp.css';

// FIREBASE
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { db, auth } from '../firebase';
import { getFunctions, httpsCallable } from "firebase/functions";

// FUNCTIONS
import { loadStripe } from '@stripe/stripe-js';

// IMAGES
import basePlanLogo from '../assets/images/baseplan-white.png';
import bumpPlanLogo from '../assets/images/bumpups-white.png';

const formatTime = (minutes) => {
  const h = Math.floor(minutes / 60);
  const m = minutes % 60;
  return `${h}h ${m}m`;
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);

const PlanPopUp = ({ isOpen, onClose }) => {
  const [selectedPlan, setSelectedPlan] = useState('Base');
  const [selectedMinutes, setSelectedMinutes] = useState(100);
  const [timeBank, setTimeBank] = useState(null);
  const [stripeButtonText, setStripeButtonText] = useState("Proceed to Stripe");

  const handleMinutesChange = async (e) => {
    setSelectedMinutes(e.target.value);
  };

  const calculatePrice = () => {
    let multiplier;
    if (selectedPlan === 'Base') {
      multiplier = 1.99;
    } else if (selectedPlan === 'Bump') {
      multiplier = 9.99;
    } else {
      multiplier = 1.99; // Default to Base if the plan is not recognized
    }
    
    return (selectedMinutes / 100 * multiplier).toFixed(2);
  };

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const subscriptionDocRef = doc(db, 'users', user.uid, 'subscriptionData', 'timeWarden');

      const fetchTimeData = async () => {
        const sessionTimeBank = sessionStorage.getItem(`timeBank_${user.uid}`);
        if (sessionTimeBank !== null) {
          setTimeBank(parseInt(sessionTimeBank, 10));
        } else {
          const docSnap = await getDoc(subscriptionDocRef);
          if (docSnap.exists()) {
            const timeBank = docSnap.data().timeBank || 0;
            setTimeBank(timeBank);
            sessionStorage.setItem(`timeBank_${user.uid}`, timeBank.toString());
          }
        }
      };

      fetchTimeData();

      const unsubscribe = onSnapshot(subscriptionDocRef, (docSnap) => {
        if (docSnap.exists()) {
          const newTimeBank = docSnap.data().timeBank || 0;
          const sessionTimeBank = sessionStorage.getItem(`timeBank_${user.uid}`);
          if (!sessionTimeBank || parseInt(sessionTimeBank, 10) !== newTimeBank) {
            setTimeBank(newTimeBank);
            sessionStorage.setItem(`timeBank_${user.uid}`, newTimeBank.toString());
          }
        }
      });

      return () => {
        unsubscribe();
      };
    }
  }, []);

  const startCheckoutSession = async () => {
    setStripeButtonText("Loading...");
    try {
      const functions = getFunctions();
      const processCheckout = httpsCallable(functions, 'processCheckout');
           
      // Logging selectedMinutes and selectedPlan before the request
      // console.log('Selected Minutesss:', selectedMinutes);
      // console.log('Selected Plan:', selectedPlan);
      const response = await processCheckout({ selectedMinutes: selectedMinutes, planType: selectedPlan });
      const { id } = response.data;
      const stripe = await stripePromise;
      await stripe.redirectToCheckout({ sessionId: id });
      setStripeButtonText("Proceed to Stripe");
    } catch (error) {
      // console.error('Error during checkout:', error);
      setStripeButtonText("Retry");
    }
  };

  if (!isOpen) return null;

  return (
    <div className="overlay-planpopup" onClick={onClose}>
      <div className="plan-popup-wrapper" onClick={(e) => e.stopPropagation()}>
        <div className="plan-popup-container">
          <button className="close-btn" onClick={onClose}>X</button>
          <h2 className="popup-header">Running low on credits?</h2>
            <p className="bplan-learn-more-text">
              Unsure which plan fits your needs?{' '}
              <span onClick={onClose}>
                <Link to="/pricing" className="bplan-learn-more-link">Learn more</Link>
              </span>
            </p>
          <div className="select-wrapper">
            <select 
              className="plan-choice-select" 
              onChange={(e) => setSelectedPlan(e.target.value)} 
              value={selectedPlan}
            >
              <option value="Base">Base</option>
              <option value="Bump">Bump</option>
            </select>
          </div>
          {(selectedPlan === 'Base' || selectedPlan === 'Bump') && (
            <div className="plan-info">
              <p className="monthly-text">
                Monthly <img 
                          src={selectedPlan === 'Base' ? basePlanLogo : bumpPlanLogo} 
                          alt={selectedPlan === 'Base' ? "Base Plan Logo" : "Bump Plan Logo"} 
                          className="bumpup-logo" 
                        />
              </p>
              <p className="price-text">${calculatePrice()} USD</p>
              <p 
                  className="upload-minutes-text" 
                  style={{ color: selectedPlan === 'Base' ? '#ffbf00' : '#4add80' }}
                >
                  {selectedMinutes} upload minutes
                </p>
              <p className="expiry-info-text">Receive monthly upload time,<br /> expires in 60 days</p>
              <select 
                className="minutes-select plan-select" 
                onChange={handleMinutesChange} 
                value={selectedMinutes}
              >
                <option key={100} value={100}>100</option>
                <option key={200} value={200}>200</option>
                <option key={400} value={400}>400</option>
                <option key={600} value={600}>600</option>
                <option key={800} value={800}>800</option>
                <option key={1000} value={1000}>1000</option>
              </select>
            </div>
          )}
          <div className="additional-info">
            <p className="rebuy-info">Rebuy/Upgrade initiates a <strong>new plan at full cost</strong> and new upload time will be <strong>available instantly and expire in 60 days</strong>.</p>
            <p className="current-credits-info">Your current {timeBank ? formatTime(timeBank) : 'N/A'} of upload time will extend to the end of your new monthly billing period.</p>
            <p className="new-plan-info">The new plan renews monthly at US${calculatePrice()} and your previous plan will be discontinued.</p>
          </div>
          <button className="proceed-btn" onClick={startCheckoutSession}>{stripeButtonText}</button>
        </div>
      </div>
    </div>
  );
};

export default PlanPopUp;
